/* For desktop */
.stream_wrapper {
    width: 400px;
    /* Set a fixed width for desktop */
    height: 300px;
    /* Set a fixed height for desktop */
    margin: 10px;
    display: inline-block;
    overflow: hidden;
    position: relative;
}

.local_class_peep {
    width: 150px;
    height: 170px;
    position: fixed;
    top: 60px;
    left: 10px;
    z-index: 100000;
}

.live_stream_div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

/* For mobile */
@media only screen and (max-width: 768px) {
    .stream_wrapper {
        width: 100%;
        /* Full width for mobile */
        height: 200px;
        /* Adjust height for mobile */
    }
}