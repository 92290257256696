/* @import url("../../App.css"); */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i");
@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 400;
  src: local("Titillium Web Regular"), local("TitilliumWeb-Regular"),
    url(https://fonts.gstatic.com/s/titilliumweb/v7/NaPecZTIAOhVxoMyOr9n_E7fdM3mDbRS.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 400;
  src: local("Titillium Web Regular"), local("TitilliumWeb-Regular"),
    url(https://fonts.gstatic.com/s/titilliumweb/v7/NaPecZTIAOhVxoMyOr9n_E7fdMPmDQ.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
video {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  background: black;
}
/* 
html,
body {
  width: 100%;
  height: 100%;
}

html {
  position: relative;
  font-size: 13px;
} */
/* 
body {
  background-color: #bbc2ce;
  font-family: "Open Sans", sans-serif;
} */

.wrapper {
  position: relative;
  overflow: hidden;
  min-height: 100%;
  background-color: #ffffff78;
}

.boxed-wrapper .wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1270px;
}

* {
  outline: none !important;
}

a:focus,
a:hover,
a:active {
  text-decoration: none;
}

.content-appear > .wrapper {
  -webkit-transform: rotateY(10deg) translateX(-150px);
  transform: rotateY(10deg) translateX(-150px);
  -webkit-transition: 0.4s all ease-in-out;
  transition: 0.4s all ease-in-out;
  opacity: 0;
}

.content-appearing > .wrapper {
  -webkit-transform: rotateY(0deg) translateX(0);
  transform: rotateY(0deg) translateX(0);
  opacity: 1;
}

.preloader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.8);
}

.preloader:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border: 2px solid #20b9ae;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 425ms infinite linear;
  -o-animation: rotate 425ms infinite linear;
  -ms-animation: rotate 425ms infinite linear;
  -webkit-animation: rotate 425ms infinite linear;
  -moz-animation: rotate 425ms infinite linear;
}

.site-overlay {
  display: none;
  position: fixed;
  z-index: 98;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.pad-top-18 {
  padding-top: 18px;
}

@media (max-width: 991px) {
  .site-sidebar-opened .site-overlay {
    display: block;
  }
}

/* =================================================================
  Sidebar
================================================================= */

.site-sidebar {
  position: absolute;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  margin-top: 60px;
  width: 220px;
  background-color: #222123;
}

@media (max-width: 767px) {
  .site-sidebar {
    left: -220px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .site-sidebar-opened .site-sidebar {
    left: 0;
  }
}

.site-sidebar .custom-scroll {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.site-sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.site-sidebar .sidebar-menu .menu-title {
  margin-top: 2.75rem;
  padding: 0.5rem 1.25rem;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  border-left: 5px solid #f44236;
  border-bottom: 1px solid #ffffff1f;
}

.site-sidebar .sidebar-menu li {
  position: relative;
}

.site-sidebar .sidebar-menu li > a {
  display: block;
  position: relative;
  padding: 0.75rem 1.25rem;
  color: rgba(255, 255, 255, 0.7);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  border-left: 5px solid #f44236;
}

.site-sidebar .sidebar-menu li > a:hover,
.site-sidebar .sidebar-menu li.active > a {
  color: #fff;
}

.site-sidebar .sidebar-menu > li.active > a {
  background-color: rgba(255, 255, 255, 0.1);
}

@media (min-width: 768px) {
  .site-sidebar .sidebar-menu > li.active > a:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -6px;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 6px 6px 6px 0;
    border-right-color: #e8ebf0;
  }
}

.site-sidebar .sidebar-menu > li > a .s-icon {
  float: left;
  margin-right: 1.125rem;
}

.site-sidebar .sidebar-menu > li > a .s-icon i {
  position: relative;
  top: 1px;
  font-size: 1.15rem;
}

.site-sidebar .sidebar-menu > li > a .s-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.site-sidebar .sidebar-menu > li.with-sub > a .s-caret {
  float: right;
  margin-left: 1rem;
  font-size: 14px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.site-sidebar .sidebar-menu > li.with-sub.active > a .s-caret {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.site-sidebar .sidebar-menu > li > a .tag {
  float: right;
  margin-top: 2px;
  border-radius: 1rem;
}

.site-sidebar .sidebar-menu ul {
  display: none;
}

.site-sidebar .sidebar-menu ul ul {
  margin-left: 1rem;
  display: none;
}

.site-sidebar .sidebar-menu ul li > a {
  padding: 0.5rem 1rem 0.5rem 3.5rem;
}

/* Layout settings */
.fixed-sidebar .site-sidebar {
  position: fixed;
}

/* Sidebar widgets */
.site-sidebar .sidebar-menu .progress-widget {
  padding: 0.75rem 1rem;
  font-size: 0.75rem;
}

.site-sidebar .sidebar-menu .progress-widget.progress-widget-light {
  color: #eee;
}

.site-sidebar
  .sidebar-menu
  .progress-widget.progress-widget-light
  .progress[value] {
  background-color: rgba(255, 255, 255, 0.4);
}

.site-sidebar
  .sidebar-menu
  .progress-widget.progress-widget-light
  .progress[value]::-webkit-progress-bar {
  background-color: rgba(255, 255, 255, 0.4);
}

@media screen and (min-width: 0\0) {
  .site-sidebar .sidebar-menu .progress-widget.progress-widget-light .progress {
    background-color: rgba(255, 255, 255, 0.4);
  }
}

.site-sidebar .sidebar-menu .progress-widget.progress-widget-dark {
  color: #333;
}

/* =================================================================
  Header
================================================================= */

.fixed-header .site-header {
  z-index: 100;
}

.fixed-header .site-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.site-header .navbar {
  padding: 0;
  background-color: #fff;
}

.site-header .navbar-left {
  height: 60px;
  background-color: #fff;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid #ddd;
}

@media (min-width: 768px) {
  .site-header .navbar-left {
    float: left;
    width: 220px;
  }

  .compact-sidebar .site-header .navbar-left {
    width: 70px;
  }
}

.site-header .navbar-brand {
  display: inline-block;
  float: none;
  margin: 0;
  padding: 0.5rem 0;
  height: 60px;

  /*display: block;*/
  /*float: none;*/
  /*margin: 0;*/
  /*padding: 5px 25px;*/
  /*margin: 0;*/
  /*height: 76px;*/
}

.site-header .navbar-right {
  padding: 0 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .site-header .navbar-right {
    margin-left: 220px;
    height: 60px !important;
  }

  .compact-sidebar .site-header .navbar-right {
    margin-left: 70px;
  }
}

.site-header .toggle-button {
  position: relative;
  width: 55px;
  height: 60px;
  line-height: 60px;
  font-size: 1.5rem;
  cursor: pointer;
}

.site-header .navbar-right .toggle-button {
  margin-left: -15px;
}

.site-header .toggle-button.light {
  display: none;
  color: rgba(0, 0, 0, 0.5);
}

.site-header .toggle-button.dark {
  color: rgba(255, 255, 255, 0.5);
}

.site-header .toggle-button span {
  position: absolute;
  display: block;
  top: 50%;
  right: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.site-header .toggle-button span.hamburger {
  left: 15px;
  margin-top: -1px;
  width: 22px;
  height: 2px;
}

.site-header .toggle-button span.more {
  left: 50%;
  margin: -2px 0 0 -2px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.site-header .toggle-button.light span {
  background-color: rgba(0, 0, 0, 0.5);
}

.site-header .toggle-button.dark span {
  background-color: rgba(255, 255, 255, 0.5);
}

.site-header .toggle-button span:before,
.site-header .toggle-button span:after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.site-header .toggle-button span.hamburger:before,
.site-header .toggle-button span.hamburger:after {
  height: 2px;
}

.site-header .toggle-button span.more:before,
.site-header .toggle-button span.more:after {
  height: 4px;
  border-radius: 50%;
}

.site-header .toggle-button.light span:before,
.site-header .toggle-button.light span:after {
  background-color: rgba(0, 0, 0, 0.5);
}

.site-header .toggle-button.dark span:before,
.site-header .toggle-button.dark span:after {
  background-color: rgba(255, 255, 255, 0.5);
}

.site-header .toggle-button span.hamburger:before {
  top: -6px;
}

.site-header .toggle-button span.hamburger:after {
  bottom: -6px;
}

.site-header .toggle-button span.more:before {
  top: -8px;
}

.site-header .toggle-button span.more:after {
  bottom: -8px;
}

.site-header .toggle-button.light:hover span,
.site-header .toggle-button.light:hover span:before,
.site-header .toggle-button.light:hover span:after,
.site-header .toggle-button.light:focus span,
.site-header .toggle-button.light:focus span:before,
.site-header .toggle-button.light:focus span:after {
  background-color: #333;
}

.site-header .toggle-button.dark:hover span,
.site-header .toggle-button.dark:hover span:before,
.site-header .toggle-button.dark:hover span:after,
.site-header .toggle-button.dark:focus span,
.site-header .toggle-button.dark:focus span:before,
.site-header .toggle-button.dark:focus span:after {
  background-color: #fff;
}

.site-header .toggle-button-second {
  width: 45px;
  height: 59px;
  line-height: 60px;
  text-align: center;
  cursor: pointer;
}

.site-header .navbar-right .toggle-button-second {
  margin: 0 -15px 0 15px;
}

.site-header .toggle-button-second.light {
  /* border-left: 1px solid rgba(0, 0, 0, 0.05); */
  color: rgba(0, 0, 0, 0.5);
}

.chat-notifi {
  position: relative;
  top: -13px !important;
  margin-left: 0.5rem;
  border-radius: 1rem;
  right: 8px !important;
}

.site-header .toggle-button-second.dark {
  border-left: 1px solid rgba(255, 255, 255, 0.125);
  color: rgba(255, 255, 255, 0.7);
}

.site-header .toggle-button-second i {
  display: inline-block;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.site-header .toggle-button-second.active i {
  /* -webkit-transform: rotate(180deg);
  transform: rotate(180deg); */
}

.site-header .navbar-nav > li > a,
.site-header .navbar-nav > li > span {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (min-width: 768px) {
  .site-header .navbar-nav > li > a,
  .site-header .navbar-nav > li > span {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 60px;
    font-weight: 700;
  }
}

.site-header .navbar-nav > li > a i {
  position: relative;
  top: 2px;
  font-size: 1.3rem;
}

.site-header .navbar-nav > li .btn {
  vertical-align: baseline;
}

.site-header .navbar-nav > li .avatar img {
  vertical-align: top;
}

.site-header .navbar .dropdown-menu {
  margin-top: -1px;
}

@media (max-width: 767px) {
  .site-header .navbar .navbar-toggleable-sm {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    padding: 1rem 2rem;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
  }
}

@media (max-width: 991px) {
  .site-header .navbar {
    margin-left: 0 !important;
  }
}

.site-header .header-form {
  padding-top: 1.1rem;
}

@media (max-width: 767px) {
  .site-header .header-form {
    padding: 0.75rem 0;
  }
}

.site-header .header-form form {
  position: relative;
}

.site-header .header-form .btn {
  position: absolute;
  top: 1px;
  right: 1px;
  color: #999;
}

@media (min-width: 768px) {
  .site-header .header-form .form-control {
    width: 180px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .site-header .header-form .form-control:focus {
    width: 300px;
  }
}

.site-header .dropdown-more {
  display: block;
  padding: 0.75rem 1rem;
  background-color: #eee;
  color: #999;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.site-header .dropdown-more:hover,
.site-header .dropdown-more:focus {
  color: #333;
}

/* Apps */
.site-header .dropdown-apps {
  padding: 0;
  min-width: 300px;
}

.site-header .dropdown-apps .a-grid {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.site-header .dropdown-apps .a-item {
  text-align: center;
}

.site-header .dropdown-apps .a-item a {
  display: block;
  padding: 1rem 0;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid transparent;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.site-header .dropdown-apps .a-item a:hover,
.site-header .dropdown-apps .a-item a:focus {
  border-color: rgba(0, 0, 0, 0.125);
  color: rgba(0, 0, 0, 0.9);
}

.site-header .dropdown-apps .a-item .ai-icon {
  margin: 0 auto 1rem auto;
  width: 32px;
  height: 32px;
}

.site-header .dropdown-apps .a-item .ai-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Tasks */
.site-header .dropdown-tasks {
  padding: 0;
  min-width: 300px;
}

.site-header .dropdown-tasks .t-item {
  display: block;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.site-header .dropdown-tasks .t-item:last-child {
  border-bottom: none;
}

.site-header .dropdown-tasks a.t-item {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.site-header .dropdown-tasks .t-item .progress {
  margin-bottom: 0.5rem;
}

.site-header .dropdown-tasks .t-item .avatar {
  margin-right: 0.5rem;
}

/* Messages */
.site-header .dropdown-messages {
  padding: 0;
  min-width: 300px;
}

.site-header .dropdown-messages .m-item {
  display: block;
  position: relative;
  padding: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.site-header .dropdown-messages .m-item:last-child {
  border-bottom: none;
}

.site-header .dropdown-messages .m-item {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.site-header .dropdown-messages .m-item .mi-icon {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 0.75rem;
  text-align: center;
  border-radius: 50%;
}

.site-header .dropdown-messages .m-item .mi-text {
  color: #999;
}

.site-header .dropdown-messages .m-item .mi-time {
  font-size: 90%;
  color: #999;
}

/* =================================================================
  Sidebar second
================================================================= */

.site-sidebar-second {
  position: absolute;
  z-index: 96;
  right: -350px;
  /* top: 60px; */
  top: 60px;
  width: 350px;
  background: #ffffff;
  border-left: transparent;
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  /*height: 100%;*/
  /*}*/
  /*position: absolute;*/
  /*z-index: 96;*/
  /*right: -350px;*/
  /*top: 60px;*/
  /*bottom: 0;*/
  /*width: 350px;*/
  /*background: #ffffff;*/
  /*border-left: 1px solid rgba(0, 0, 0, 0.1);*/
  /*box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.1);*/
  /*-webkit-transition: all 0.2s ease-in-out;*/
  /*transition: all 0.2s ease-in-out;*/
}

.toast-top-center {
  top: 30px;
  margin: 0 auto;
}

.fixed-header .site-sidebar-second {
  position: fixed;
}

.site-sidebar-second.opened {
  right: 0;
}

.site-sidebar-second .nav-tabs {
  width: 100%;
  background-color: #eee;
  border: none;
}

.site-sidebar-second .nav-tabs .nav-item {
  display: table-cell;
  float: none;
  margin-bottom: 0;
  width: 1%;
  text-align: center;
}

.site-sidebar-second .nav-tabs .nav-item + .nav-item {
  margin-left: 0;
}

.site-sidebar-second .nav-tabs .nav-link {
  padding: 0.75em 1em;
  color: #999;
  border: none;
}

.site-sidebar-second .nav-tabs .nav-link.active,
.site-sidebar-second .nav-tabs .nav-link.active:focus,
.site-sidebar-second .nav-tabs .nav-link.active:hover {
  color: #333;
}

.site-sidebar-second .nav-tabs .nav-item.open .nav-link,
.site-sidebar-second .nav-tabs .nav-item.open .nav-link:focus,
.site-sidebar-second .nav-tabs .nav-item.open .nav-link:hover {
  border-color: #eee;
}

.site-sidebar-second .tab-content {
  padding: 1rem 0;
}

.site-sidebar-second h6 {
  margin-bottom: 1rem;
  font-weight: normal;
  color: #999;
  text-transform: uppercase;
}

.site-sidebar-second .sidebar-group {
  padding: 1.5rem 0;
}

/* Chat */
.site-sidebar-second .sidebar-chat h6 {
  padding: 0 2rem;
}

.site-sidebar-second .sidebar-chat a {
  display: block;
  position: relative;
  padding: 0.5rem 2rem;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.site-sidebar-second .sidebar-chat a:hover {
  background-color: #f5f5f5;
}

.site-sidebar-second .sidebar-chat span {
  display: inline-block;
}

.site-sidebar-second .sidebar-chat .sc-status {
  margin-right: 1rem;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.site-sidebar-second .sidebar-chat .sc-name {
  margin-right: 1rem;
}

.site-sidebar-second .sidebar-chat .tag {
  position: relative;
  top: -2px;
  border-radius: 1rem;
}

.site-sidebar-second .sidebar-chat .sc-writing {
  position: relative;
  padding-left: 25px;
  color: #999;
  font-size: 90%;
}

.site-sidebar-second .sidebar-chat .sc-writing i {
  position: absolute;
  top: 3px;
  left: 0;
  -webkit-animation-name: writing;
  -o-animation-name: writing;
  animation-name: writing;
  -webkit-animation-duration: 1.2s;
  -o-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* Chat window */
.site-sidebar-second .sidebar-chat-window {
  display: none;
  padding: 0 0rem;
}

.site-sidebar-second .sidebar-chat-window .scw-header {
  padding: 15px;
  width: 100%;
  background: #0275d8;
  margin: 0;
  color: #fff;
}

.site-sidebar-second .sidebar-chat-window .scw-header a {
  display: block;
  margin-top: 5px;
}

.site-sidebar-second .sidebar-chat-window .scw-header .avatar {
  display: inline-block;
  margin-left: 0.5rem;
}

.site-sidebar-second .sidebar-chat-window .scw-item {
  margin-bottom: 1.5rem;
  text-align: left;
  word-wrap: break-word;
}

.site-sidebar-second .sidebar-chat-window .scw-item.self {
  text-align: right;
  word-wrap: break-word;
}

.site-sidebar-second .sidebar-chat-window .scw-item span {
  display: inline-block;
  padding: 0.5rem 1rem;
  max-width: 100%;
  background-color: #eee;
  text-align: left;
  width: 100%;
}

.site-sidebar-second .sidebar-chat-window .scw-item.self span {
  background-color: #43464aa1;
  color: #fff;
}

.site-sidebar-second .sidebar-chat-window .scw-form {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  /*position: fixed;*/
  /*bottom: 70px;*/
  width: 100%;
  z-index: 999;
  background: #fff;
}

.site-sidebar-second .sidebar-chat-window .scw-form form {
  position: relative;
  padding: 0 15px !important;
}

.site-sidebar-second .sidebar-chat-window .scw-form .form-control {
  border-color: transparent;
  background-color: transparent;
}

.site-sidebar-second .sidebar-chat-window .scw-form .btn {
  position: absolute;
  top: -2px;
  background-color: #fff !important;
  right: 0;
  background-color: transparent;
  font-size: 0.75rem;
  color: #999;
  border: none;
  box-shadow: none;
}

/* Activity */
.site-sidebar-second .sidebar-activity {
  padding: 2rem;
}

/* Todo */
.site-sidebar-second .sidebar-todo {
  padding: 0 2rem;
}

.site-sidebar-second .sidebar-todo .st-item {
  position: relative;
  margin-bottom: 1rem;
  padding-right: 3rem;
}

.site-sidebar-second
  .sidebar-todo
  .st-item
  input[type="checkbox"]:checked
  + span
  + span {
  text-decoration: line-through;
  color: #999;
}

/* Settings */
.site-sidebar-second .sidebar-settings {
  padding: 0 2rem;
}

.site-sidebar-second .sidebar-settings .ss-item {
  position: relative;
  margin-bottom: 1rem;
  padding-right: 3rem;
}

.site-sidebar-second .sidebar-settings .ss-checkbox {
  position: absolute;
  top: 0;
  right: 0;
}

/* =================================================================
  Template options
================================================================= */

.template-options {
  position: absolute;
  z-index: 97;
  right: -320px;
  top: 60px;
  bottom: 0;
  width: 320px;
  background: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

@media (min-width: 544px) {
  .template-options {
    right: -400px;
    width: 400px;
  }
}

@media (min-width: 992px) {
  .template-options {
    z-index: 99;
  }
}

.fixed-header .template-options {
  position: fixed;
}

.template-options .custom-scroll {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.template-options.opened {
  right: 0;
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.1);
}

.template-options .to-content {
  padding: 2rem;
}

@media (max-width: 800px) {
  .template-options .to-content {
    padding: 3rem;
  }

  .pad-20-30 {
    padding: 9px 0px !important;
    width: 42px !important;
    margin: 0 auto !important;
    font-size: 8px !important;
  }

  .padd-80 {
    padding: 0 20px !important;
  }
}

.template-options .to-content h6 {
  margin-bottom: 1rem;
  font-weight: normal;
  color: #999;
  text-transform: uppercase;
}

.template-options a,
.template-options label {
  display: block;
  position: relative;
  margin-bottom: 0rem;
}

.template-options label,
.template-options label:focus,
.template-options label:hover {
  cursor: pointer;
}

.template-options label.skin-label {
  width: 50px;
}

.template-options input {
  /*position: absolute;*/
  /*z-index: -1;*/
  /*opacity: 0;*/
}

.template-options .disabled {
  cursor: not-allowed;
}

.template-options .disabled label {
  background-color: #aaa;
  pointer-events: none;
}

.template-options .to-icon {
  position: absolute;
  z-index: 10;
  opacity: 0;
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: #20b9ae;
  font-size: 1.25rem;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}

.template-options input:checked + .to-icon {
  opacity: 1;
}

.template-options .to-material {
  position: relative;
  padding-left: 4rem;
}

.template-options .to-material .tom-checkbox {
  position: absolute;
  top: -2px;
  left: 0;
}

.template-options .to-skin {
  position: relative;
  width: 50px;
  height: 50px;
}

.template-options .to-skin:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.template-options .to-skin:after {
  content: "";
  display: table;
  clear: both;
}

.template-options .to-skin span {
  display: inline-block;
  float: left;
  width: 25px;
  height: 25px;
}

.template-options .to-skin .skin-dark-blue {
  background-color: #212330;
}

.template-options .to-skin .skin-dark-blue-2 {
  background-color: #373944;
}

.template-options .to-skin .skin-grey {
  background-color: #ddd;
}

/* Toggle */
.template-options .to-toggle {
  position: absolute;
  top: 200px;
  left: -45px;
  width: 45px;
  height: 45px;
  line-height: 47px;
  background-color: #fff;
  font-size: 1.4rem;
  color: #999;
  text-align: center;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-right: none;
  box-shadow: -10px 0 30px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.template-options .to-toggle:hover {
  color: #333;
}

/* =================================================================
  Skins
================================================================= */

/* Skin 1 */
.skin-1 .site-sidebar {
  background-color: #fff;
}

.skin-1 .site-sidebar .sidebar-menu .menu-title {
  color: rgba(0, 0, 0, 0.5);
}

.skin-1 .site-sidebar .sidebar-menu li > a {
  color: rgba(0, 0, 0, 0.7);
}

.skin-1 .site-sidebar .sidebar-menu li > a:hover,
.skin-1 .site-sidebar .sidebar-menu li.active > a {
  color: #333;
}

.skin-1 .site-sidebar .sidebar-menu > li.active > a,
.skin-1.compact-sidebar .site-sidebar .sidebar-menu > li:hover {
  background-color: #eee;
}

.skin-1 .site-header .navbar-left {
  background-color: #373944;
}

.skin-1 .site-header .navbar {
  background-color: #373944;
}

/* Skin 2 */
.skin-2 .site-sidebar {
  background-color: #111;
}

.skin-2 .site-header .navbar-left {
  background-color: #e31e24;
}

/* Skin 3 */
.skin-3 .site-sidebar {
  background-color: #fff;
}

.skin-3 .site-sidebar .sidebar-menu .menu-title {
  color: rgba(0, 0, 0, 0.5);
}

.skin-3 .site-sidebar .sidebar-menu li > a {
  color: rgba(0, 0, 0, 0.7);
}

.skin-3 .site-sidebar .sidebar-menu li > a:hover,
.skin-3 .site-sidebar .sidebar-menu li.active > a {
  color: #333;
}

.skin-3 .site-sidebar .sidebar-menu > li.active > a,
.skin-3.compact-sidebar .site-sidebar .sidebar-menu > li:hover {
  background-color: #eee;
}

.skin-3 .site-header .navbar-left {
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* Skin 1 */
.skin-4 .site-sidebar {
  background-color: #fff;
}

.skin-4 .site-sidebar .sidebar-menu .menu-title {
  color: rgba(0, 0, 0, 0.5);
}

.skin-4 .site-sidebar .sidebar-menu li > a {
  color: rgba(0, 0, 0, 0.7);
}

.skin-4 .site-sidebar .sidebar-menu li > a:hover,
.skin-4 .site-sidebar .sidebar-menu li.active > a {
  color: #333;
}

.skin-4 .site-sidebar .sidebar-menu > li.active > a,
.skin-4.compact-sidebar .site-sidebar .sidebar-menu > li:hover {
  background-color: #eee;
}

.skin-4 .site-header .navbar-left {
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.skin-4 .site-header .navbar {
  background-color: #373944;
}

/* Skin 5 */
.skin-5 .site-sidebar {
  background-color: #fff;
}

.skin-5 .site-sidebar .sidebar-menu .menu-title {
  color: rgba(0, 0, 0, 0.5);
}

.skin-5 .site-sidebar .sidebar-menu li > a {
  color: rgba(0, 0, 0, 0.7);
}

.skin-5 .site-sidebar .sidebar-menu li > a:hover,
.skin-5 .site-sidebar .sidebar-menu li.active > a {
  color: #333;
}

.skin-5 .site-sidebar .sidebar-menu > li.active > a,
.skin-5.compact-sidebar .site-sidebar .sidebar-menu > li:hover {
  background-color: #eee;
}

.skin-5 .site-header .navbar-left {
  background-color: #3e70c9;
}

.skin-5 .site-header .navbar {
  background-color: #3e70c9;
}

/* Skin 6 */
.skin-6 .site-sidebar {
  background-color: #000;
}

.skin-6 .site-header .navbar-left {
  background-color: #000;
}

.skin-6 .site-header .navbar {
  background-color: #20b9ae;
}

/* =================================================================
  Navbar
================================================================= */

.navbar {
  padding: 1rem;
  border-radius: 0;
}

.navbar .navbar-toggler {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
}

@media (max-width: 767px) {
  .navbar .navbar-toggleable-sm {
    padding-top: 1rem;
  }
}

@media (min-width: 768px) {
  .navbar-nav .nav-item + .nav-item {
    margin-left: 1.5rem;
  }
}

.navbar-brand,
.nav-link {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.navbar-brand {
  font-weight: bold;
}

.navbar .avatar {
  display: inline-block;
}

.navbar .tag {
  position: relative;
  top: -1px;
  margin-left: 0.5rem;
  border-radius: 1rem;
}

@media (max-width: 767px) {
  .navbar ul > li > a .tag {
    float: right;
  }
}

@media (min-width: 768px) {
  .navbar ul > li > a .tag {
    top: -12px;
    margin-left: 0;
  }
}

.form-inline .input-group .form-control,
.form-inline .input-group .input-group-addon,
.form-inline .input-group .input-group-btn {
  width: auto;
}

.navbar .navbar-toggleable-sm .form-inline .input-group .form-control {
  width: 100%;
}

@media (max-width: 767px) {
  .navbar .navbar-toggleable-sm .form-inline .input-group .input-group-addon,
  .navbar .navbar-toggleable-sm .form-inline .input-group .input-group-btn {
    width: 1%;
  }
}

.navbar .dropdown-menu {
  margin: 0;
  border: 1px solid #ddd;
}

.form-inline .input-group {
  display: inline-table;
  vertical-align: middle;
}

.dropdown-menu i {
  position: relative;
  top: 1px;
}

@media (max-width: 767px) {
  .navbar .dropdown-menu {
    position: static;
    margin-bottom: 1rem;
    width: 100%;
  }
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .open > .nav-link,
.navbar-light .navbar-nav .open > .nav-link:focus,
.navbar-light .navbar-nav .open > .nav-link:hover,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .active > .nav-link:focus,
.navbar-light .navbar-nav .active > .nav-link:hover,
.navbar-light .navbar-nav .nav-link.open,
.navbar-light .navbar-nav .nav-link.open:focus,
.navbar-light .navbar-nav .nav-link.open:hover,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.active:focus,
.navbar-light .navbar-nav .nav-link.active:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-toggler {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.9);
}

.navbar-dark .navbar-nav .open > .nav-link,
.navbar-dark .navbar-nav .open > .nav-link:focus,
.navbar-dark .navbar-nav .open > .nav-link:hover,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link:focus,
.navbar-dark .navbar-nav .active > .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.open,
.navbar-dark .navbar-nav .nav-link.open:focus,
.navbar-dark .navbar-nav .nav-link.open:hover,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.active:focus,
.navbar-dark .navbar-nav .nav-link.active:hover {
  color: rgba(255, 255, 255, 0.9);
}

@media (max-width: 767px) {
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
  }

  .navbar-dark .navbar-nav .nav-link:focus,
  .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.9);
  }

  .navbar-dark .navbar-nav .open > .nav-link,
  .navbar-dark .navbar-nav .open > .nav-link:focus,
  .navbar-dark .navbar-nav .open > .nav-link:hover,
  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .active > .nav-link:focus,
  .navbar-dark .navbar-nav .active > .nav-link:hover,
  .navbar-dark .navbar-nav .nav-link.open,
  .navbar-dark .navbar-nav .nav-link.open:focus,
  .navbar-dark .navbar-nav .nav-link.open:hover,
  .navbar-dark .navbar-nav .nav-link.active,
  .navbar-dark .navbar-nav .nav-link.active:focus,
  .navbar-dark .navbar-nav .nav-link.active:hover {
    color: rgba(0, 0, 0, 0.9);
  }
}

@media (min-width: 768px) {
  .navbar-full-width {
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-full-width .navbar-nav {
    width: 100%;
  }

  .navbar-full-width .navbar-nav > li {
    display: table-cell;
    float: none;
    width: 1%;
    text-align: center;
  }

  .navbar-full-width .navbar-nav > li > a {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  .navbar-full-width .navbar-nav > li:last-child > a {
    border-right: none;
  }
}

@media (min-width: 768px) {
  .navbar-bottom-line {
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-bottom-line .navbar-brand {
    padding-top: 0.65rem;
  }

  .navbar-bottom-line .navbar-nav .nav-item + .nav-item {
    margin-left: 2rem;
  }

  .navbar-bottom-line .navbar-nav > li > a {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-bottom: 2px solid transparent;
  }

  .navbar-bottom-line .navbar-nav .active > .nav-link,
  .navbar-bottom-line .navbar-nav .active > .nav-link:focus,
  .navbar-bottom-line .navbar-nav .active > .nav-link:hover {
    color: #3e70c9;
    border-color: #3e70c9;
  }

  .navbar-bottom-line .navbar-nav .nav-link:focus,
  .navbar-bottom-line .navbar-nav .nav-link:hover {
    border-color: rgba(0, 0, 0, 0.3);
  }
}

/* =================================================================
  Content
================================================================= */

.site-content {
  margin-left: 0;
}

/* Layout settings */
.fixed-header .site-content {
  padding-top: 60px;
}

/*.fixed-footer .content-area {*/
/*!*margin-bottom: 3.5rem;*!*/
/*}*/

@media (min-width: 768px) {
  .site-content {
    margin-left: 220px;
  }

  .compact-sidebar .site-content {
    margin-left: 70px;
  }
}

/* =================================================================
  Widgets
================================================================= */

/* Banners */
.banner {
  position: relative;
  overflow: hidden;
  background-color: #fff;
}

.banner .b-img {
  position: absolute;
  z-index: 8;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.banner .gradient {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.8;
}

.banner .b-content {
  position: relative;
  z-index: 10;
  padding: 3rem 2rem;
}

.banner .b-title {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}

.banner .b-title span {
  display: inline-block;
  margin-bottom: 1rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.banner .b-text {
  color: rgba(255, 255, 255, 0.9);
}

/* Cart mini */
.cart-mini .cm-title {
  padding: 0.75rem 1.25rem;
  font-weight: bold;
  text-transform: uppercase;
}

.cart-mini .cm-title span {
  font-weight: bold;
}

.cart-mini .positions td {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.cart-mini .cm-total {
  padding-top: 7px;
}

/* Comments */
.comments .c-item {
  margin-bottom: 1rem;
}

.comments .c-item:last-child {
  margin-bottom: 0;
}

.comments .c-item .c-item {
  margin-top: 1rem;
  margin-left: 62px;
}

.comments .c-item .c-user {
  margin-right: 0.5rem;
  font-weight: bold;
}

.comments .c-item .c-text {
  margin-bottom: 0.5rem;
}

.comments .c-rating {
  padding-top: 27px;
  width: 100px;
  text-align: right;
}

.comments .c-compose textarea {
  padding: 1rem 1.25rem;
  width: 100%;
  min-height: 80px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

/* Modals */
.custom-modal-1 .cm-img {
  position: relative;
  height: 200px;
}

.custom-modal-1 .gradient {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.8;
}

.custom-modal-1 .modal-body,
.custom-modal-1 .cm-content {
  position: relative;
  z-index: 10;
}

.custom-modal-1 .cm-content {
  padding: 1.25rem;
  color: #fff;
  text-align: center;
}

.custom-modal-1 .cm-title {
  padding-top: 40px;
  font-weight: bold;
  font-size: 2rem;
  text-transform: uppercase;
}

.custom-modal-1 .cm-text {
  color: rgba(255, 255, 255, 0.7);
}

.custom-modal-2 .modal-header {
  padding: 12px 15px 10px;
  background-color: #f5f5f5;
  text-align: center;
}

.custom-modal-2 .modal-title {
  font-weight: normal;
  text-transform: uppercase;
}

.custom-modal-2 .form-control {
  background-color: #f5f5f5;
}

.custom-modal-3 {
  color: #fff;
}

.custom-modal-3 .modal-content:before {
  content: "";
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.custom-modal-3 .modal-body {
  position: relative;
  z-index: 10;
}

.custom-modal-3 h2 {
  padding-top: 150px;
}

.custom-modal-3 h6 {
  font-weight: normal;
  text-transform: uppercase;
}

.custom-modal-3 .form-control {
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.custom-modal-3 .form-control:focus {
  background-color: rgba(255, 255, 255, 0.9);
}

.custom-modal-4 .modal-content {
  position: relative;
  padding-left: 250px;
}

.custom-modal-4 .cm-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
}

.custom-modal-4 .gradient {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.8;
}

.custom-modal-4 .cm-content {
  position: absolute;
  bottom: 0;
  z-index: 10;
  padding: 1.25rem;
  color: #fff;
}

.custom-modal-4 h6 {
  font-weight: normal;
  color: rgba(255, 255, 255, 0.7);
}

.custom-modal-5 .modal-body {
  padding: 2rem 3rem;
}

.custom-modal-5 .cm-icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}

.custom-modal-5 .btn {
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.custom-modal-6 .modal-header,
.custom-modal-6 .modal-footer {
  text-align: center;
}

/* Footers */
footer .f-logo {
  font-size: 1.25rem;
  font-weight: bold;
}

footer .f-menu ul {
  margin-bottom: 0;
}

footer .f-menu ul li {
  margin-bottom: 0.25rem;
}

footer .f-menu ul li a {
  color: #777;
  border-bottom: 1px solid transparent;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

footer .f-menu ul li a:focus,
footer .f-menu ul li a:hover,
footer .f-menu ul li.active a {
  color: #333;
  border-color: #333;
}

footer .f-title {
  margin-bottom: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}

/* Gallery */
.gallery a {
  display: block;
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;
}

.gallery a img {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.gallery a:hover img {
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.gallery a .g-icon {
  position: absolute;
  z-index: 10;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.9);
  line-height: 55px;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  opacity: 0;
  filter: alpha(opacity=0);
}

.gallery a:hover .g-icon {
  opacity: 1;
  filter: alpha(opacity=100);
}

.gallery-2 .g-item {
  overflow: hidden;
  position: relative;
  background-color: #f3f7fc;
}

.gallery-2 .g-item a {
  display: block;
  overflow: hidden;
}

.gallery-2 .g-item img {
  width: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.gallery-2 .g-item:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.gallery-2 .g-item .g-item-overlay {
  position: absolute;
  bottom: -40px;
  left: 0;
  padding: 0.75rem 1.25rem;
  width: 100%;
  height: 40px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.gallery-2 .g-item:hover .g-item-overlay {
  bottom: 0;
}

/* Headers */
.header {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  color: #fff;
}

.header .gradient {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.8;
}

.header .h-content {
  position: relative;
  z-index: 10;
  padding: 2rem;
}

.header .h-logo a {
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
}

.header .h-menu {
  padding-top: 6px;
}

.header .h-menu li {
  margin-right: 1rem;
}

.header .h-menu a {
  font-size: 1.125rem;
}

.header .h-links a {
  font-size: 1.5rem;
}

.header .h-title {
  margin-bottom: 2rem;
  padding-top: 100px;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
}

.header .h-text {
  margin-bottom: 2.5rem;
  font-size: 1.125rem;
  color: rgba(255, 255, 255, 0.9);
}

.header .btn {
  margin: 0 0.5rem;
  font-size: 1rem;
  text-transform: uppercase;
}

.header .h-buttons,
.header .h-form,
.header .h-timer {
  padding-bottom: 100px;
}

.header .h-form .form-control {
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.header .h-form .form-control:focus {
  background-color: rgba(255, 255, 255, 0.9);
}

.header .h-form .input-group-addon {
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.header .h-timer .ht-item {
  display: inline-block;
  margin: 0 1rem;
  font-size: 3rem;
}

.header .h-timer .ht-item span {
  display: block;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.9);
}

.header .h-down {
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 50%;
  margin-left: -35px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
}

.header .h-down a {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
}

/* Items list */
.items-list .il-item {
  position: relative;
  overflow: hidden;
  padding: 1rem 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.items-list .il-item:hover {
  background-color: #f5f5f5;
}

.items-list .il-item .media-heading {
  margin-top: 4px;
}

.items-list .il-item .il-icon {
  position: absolute;
  top: 20px;
  right: -30px;
  font-size: 20px;
  color: #999;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.items-list .il-item:hover .il-icon {
  right: 15px;
}

/* Messenger */
.messenger {
  overflow: hidden;
}

.messenger .m-title {
  padding: 0 1.25rem;
  height: 65px;
  line-height: 65px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.messenger .m-header {
  padding: 1rem 1.25rem;
  height: 65px;
  background-color: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.messenger .m-form {
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem 1.25rem;
  background-color: #f5f5f5;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.messenger .m-form .mf-search {
  position: relative;
}

.messenger .m-form .mf-search .form-control {
  border-color: transparent;
  background-color: transparent;
}

.messenger .m-form .mf-search .btn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  color: #999;
  border: none;
  box-shadow: none;
}

.messenger .m-form .mf-compose {
  position: relative;
  padding-right: 78px;
}

.messenger .m-form .mf-compose .form-control {
  border-radius: 25rem 0 0 25rem;
}

.messenger .m-form .mf-compose .btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  border-radius: 0 25rem 25rem 0;
  box-shadow: none;
}

.messenger .m-header .mh-links {
  padding-top: 5px;
  width: 100px;
  text-align: right;
}

.messenger .m-header .mh-links a {
  font-size: 1.25em;
  margin-left: 1rem;
}

.messenger .m-contacts,
.messenger .m-chat {
  position: relative;
  height: 630px;
}

.messenger .m-contacts {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}

.messenger .m-contacts .mc-item {
  padding: 1rem 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.messenger .m-contacts .mc-item.active {
  background-color: #e5eff2;
}

.messenger .m-contacts .mc-item:last-child {
  border-bottom: none;
}

.messenger .m-contacts .mc-item .media,
.messenger .m-contacts .mc-item .media-body {
  overflow: visible;
}

.messenger .m-contacts .mc-item .tag {
  margin-left: 0.5rem;
  border-radius: 1rem;
}

.messenger .m-chat .mc-item {
  padding: 1.25rem;
}

.messenger .m-chat .mc-item.left .avatar {
  float: left;
  margin-right: 1rem;
}

.messenger .m-chat .mc-item.right .avatar {
  float: right;
  margin-left: 1rem;
}

.messenger .m-chat .mc-item .mc-content {
  background-color: #f5f5f5;
  padding: 1.25rem;
  max-width: 50%;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.messenger .m-chat .mc-item.left .mc-content {
  float: left;
}

.messenger .m-chat .mc-item.right .mc-content {
  float: right;
  background-color: #e5eff2;
}

/* Notifications */
.notifications {
  position: relative;
}

.notifications:after {
  content: "";
  position: absolute;
  z-index: 9;
  left: 24px;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #ddd;
}

.notifications .n-item {
  position: relative;
  z-index: 10;
  margin-bottom: 1.5rem;
}

.notifications .n-item:last-child {
  margin-bottom: 0;
}

.notifications .n-item .media,
.notifications .n-item.media-body {
  overflow: visible;
}

.notifications .n-item .avatar img {
  -webkit-box-shadow: 0 0 0 5px #fff;
  box-shadow: 0 0 0 5px #fff;
}

.notifications .n-item .n-icon {
  position: absolute;
  top: -5px;
  left: -5px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 3px #fff;
  box-shadow: 0 0 0 3px #fff;
}

.notifications .n-item .n-text a {
  font-weight: bold;
}

.notifications .n-item .n-comment {
  background-color: #f5f5f5;
  padding: 1rem 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

/* Posiitons */
.positions td,
.positions th {
  vertical-align: middle;
}

.positions tr:last-child td {
  border-bottom: 1px solid #eceeef;
}

.positions .p-img img {
  width: 48px;
}

.positions .p-quantity .btn {
  border-radius: 50%;
}

.positions .p-quantity span {
  margin: 0 5px;
}

/* Post */
.post .tag {
  padding: 0.4em 0.8em;
  text-transform: uppercase;
}

.post .p-content {
  padding: 1.25rem;
}

.post-1 .p-img {
  position: relative;
  height: 200px;
}

.post-1 .p-img .p-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}

.post-1 .tag {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
}

.post-1 .p-info i {
  margin-right: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
}

.post-2 {
  height: 150px;
  padding-left: 150px;
  padding-right: 75px;
}

.post-2 .p-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 150px;
}

.post-2 .p-content .p-text {
  overflow: hidden;
  max-height: 65px;
}

.post-2 .p-content .p-info {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}

.post-2 .p-content .p-info a {
  display: block;
  padding-top: 20px;
  width: 74px;
  height: 74px;
  color: #333;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.post-2 .p-content .p-info a:first-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.post-2 .p-content .p-info a:focus,
.post-2 .p-content .p-info a:hover {
  background-color: #f5f5f5;
}

.post-3 .p-img {
  position: relative;
  height: 200px;
}

.post-3 .p-author {
  position: relative;
  padding: 6px 0 0 95px;
}

.post-3 .p-author .avatar {
  position: absolute;
  top: -32px;
  left: 1.25rem;
}

.post-3 .p-info {
  background-color: #f5f5f5;
  padding: 0.75rem 1.25rem;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.post-3 .p-info i {
  margin-right: 0.5rem;
}

.post-4 {
  padding-left: 200px;
  min-height: 200px;
}

.post-4 .p-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 200px;
}

.post-4 .avatar {
  display: inline-block;
}

.post-4 .p-info {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.post-4 .p-info i {
  margin-right: 0.5rem;
}

.post-5 {
  color: #fff;
}

.post-5:before {
  content: "";
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.post-5 .p-content {
  position: relative;
  z-index: 10;
  padding-top: 200px;
}

.post-5 .p-content .small {
  color: rgba(255, 255, 255, 0.9);
}

.post-5 .tag {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
}

.post-5 .p-info {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.post-5 .p-info i {
  margin-right: 0.5rem;
}

/* Posts list */
.posts-list .pl-item {
  padding: 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.posts-list .pl-item .pli-img {
  position: relative;
  width: 150px;
}

.posts-list .pl-item a .pli-img {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.posts-list .pl-item a:hover .pli-img {
  opacity: 0.8;
}

.posts-list .pl-item .tag {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.4em 0.8em;
  text-transform: uppercase;
}

.posts-list .pl-item i {
  margin-right: 0.5rem;
}

/* Products */
.product .p-img {
  display: block;
  position: relative;
}

.product .p-content {
  padding: 1.25rem;
}

.product .p-content .p-info {
  margin-bottom: 0.5rem;
  color: #999;
}

.product-1 .p-img {
  overflow: hidden;
  height: 200px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.product-1 .p-img:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.product-1 .p-img:hover:before {
  opacity: 1;
}

.product-1 .p-status {
  position: absolute;
  top: 1.25rem;
  right: 0;
  padding: 0.5rem 1rem;
  line-height: 1;
  font-size: 90%;
  text-transform: uppercase;
  border-radius: 0.125rem 0 0 0.125rem;
}

.product-1 .p-img .p-links {
  position: absolute;
  bottom: -80px;
  left: 0;
  right: 0;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.product-1 .p-img:hover .p-links {
  bottom: 80px;
}

.product-1 .p-img .p-links a {
  display: inline-block;
  margin: 0 0.5rem;
  width: 40px;
  height: 40px;
  line-height: 38px;
  color: #fff;
  border-radius: 50%;
  border: 2px solid #fff;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.product-1 .p-img .p-links a:focus,
.product-1 .p-img .p-links a:hover {
  background-color: #fff;
  color: #333;
  text-decoration: none;
}

.product-1 .p-content h5 {
  margin-bottom: 0.25rem;
}

.product-1 .p-content .p-info {
  font-size: 90%;
}

.product-1 .p-price {
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.product-2 {
  text-align: center;
}

.product-2 .p-img {
  height: 200px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.product-2 .p-img:after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 50%;
  margin-left: -5px;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #fff;
  border-left: 9px solid transparent;
}

.product-2 .p-status {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  width: 40px;
  height: 40px;
  line-height: 42px;
  font-size: 90%;
  text-transform: uppercase;
  border-radius: 50%;
}

.product-2 .p-content h5 {
  margin-bottom: 0.25rem;
}

.product-2 .p-content .p-info {
  font-size: 90%;
}

.product-2 .p-price {
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.product-2 .p-price span {
  margin: 0 0.5rem;
}

.product-2 .p-price span.old {
  font-size: 1rem;
  font-weight: normal;
  text-decoration: line-through;
}

.product-3 .p-img {
  overflow: hidden;
  padding: 0 1.25rem;
  height: 200px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.product-3 .p-img:before {
  content: "";
  position: absolute;
  z-index: 9;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.product-3 .p-img:hover:before {
  opacity: 1;
}

.product-3 .p-img .btn {
  position: relative;
  z-index: 10;
  margin-top: -100px;
}

.product-3 .p-img:hover .btn {
  margin-top: 84px;
}

.product-3 .p-content h5 {
  margin-bottom: 0;
}

.product-3 .p-content .p-info {
  font-size: 90%;
}

.product-3 .p-info {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.product-4 .p-img {
  height: 200px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.product-4 .p-price span.old {
  margin-right: 0.5rem;
  font-weight: normal;
  color: #999;
  text-decoration: line-through;
}

.product-4 .p-links {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.product-4 .p-links .pl-item {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}

.product-4 .p-links .pl-item:last-child {
  border-right: none;
}

.product-4 .p-links .pl-item a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0.75rem 0 0.5rem;
  color: #999;
  text-align: center;
}

.product-4 .p-links .pl-item a:focus,
.product-4 .p-links .pl-item a:hover {
  color: #333;
  background-color: #f5f5f5;
  text-decoration: none;
}

.product-4 .p-links .pl-item a i {
  font-size: 1.25rem;
}

.product-5 {
  overflow: hidden;
  padding-left: 200px;
}

.product-5 .p-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 200px;
}

.product-5 .p-status {
  padding: 0.5rem 1rem;
  line-height: 1;
  font-size: 80%;
  text-transform: uppercase;
  border-radius: 0.125rem;
}

.product-5 .p-timer {
  padding-top: 2px;
  color: #999;
}

.product-5 .p-timer i {
  margin-right: 0.25rem;
  position: relative;
  top: 1px;
}

.product-5 h5 {
  margin-top: 3rem;
}

.product-5 .p-content .p-info {
  margin-bottom: 0.75rem;
}

.product-5 .p-category {
  margin-top: 3rem;
  font-size: 90%;
}

/* Product view */
.product-view .pv-images a {
  display: block;
}

.product-view .pv-images img {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.product-view .pv-images a:hover img {
  opacity: 0.7;
}

.product-view .pv-content {
  margin-bottom: 1.5rem;
}

.product-view .pv-content .pv-title {
  position: relative;
  padding-right: 50px;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: normal;
  text-transform: uppercase;
}

.product-view .pv-content .pv-title a {
  display: block;
  position: absolute;
  top: 2px;
  right: 0;
  font-size: 1.5rem;
}

.product-view .pv-content .fa-ul i {
  position: relative;
  top: -1px;
  margin-right: 0.5rem;
  font-size: 70%;
  color: #999;
}

.product-view .pv-form {
  margin-bottom: 1.25rem;
  padding: 1.5rem;
  background-color: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.product-view .pv-form .pv-price {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.product-view .pv-form .pv-price span.old {
  margin-right: 1rem;
  font-weight: normal;
  color: #999;
  text-decoration: line-through;
}

/* Share */
.share {
  overflow: hidden;
  background-color: #fff;
  border-radius: 0.25rem;
}

.share .s-item a {
  display: block;
  padding: 1rem 0 0.75rem;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.share .s-item a:hover {
  opacity: 0.8;
}

.share .s-item i {
  font-size: 1.25rem;
}

.share .s-item span {
  display: block;
}

.share-1 .s-item i {
  color: #fff;
}

.share-1 .s-item span {
  color: rgba(255, 255, 255, 0.7);
}

.share-2 .s-item {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}

.share-2 .s-item:last-child {
  border-right: none;
}

/* Social */
.social {
  color: #fff;
}

.social .s-title {
  font-weight: bold;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.social .s-title i {
  position: relative;
  top: 2px;
  margin-right: 0.5rem;
  font-size: 1.25rem;
}

.social .s-time {
  color: rgba(255, 255, 255, 0.8);
}

/* Steps */
.steps .s-numbers {
  position: relative;
  margin-bottom: 2rem;
}

.steps .s-numbers:before {
  content: "";
  position: absolute;
  z-index: 9;
  top: 20px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #ddd;
}

.steps .s-number {
  position: relative;
  z-index: 10;
}

.steps .s-number span {
  display: inline-block;
}

.steps .s-number .sn-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #ccc;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 10px #fff;
  box-shadow: 0 0 0 10px #fff;
}

.steps .s-number.complete .sn-icon {
  background-color: #43b968;
}

.steps .s-number.active .sn-icon {
  background-color: #3e70c9;
}

.steps .s-number .sn-text {
  margin-top: 5px;
  text-transform: uppercase;
  color: #999;
}

.steps .s-number.complete .sn-text {
  color: #43b968;
}

.steps .s-number.active .sn-text {
  color: #3e70c9;
}

.steps .c-select {
  width: 100%;
}

/* Tiles */
.tile {
  position: relative;
  overflow: hidden;
}

.tile .t-content {
  position: relative;
  z-index: 10;
}

.tile h1 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}

.tile h6 {
  font-weight: normal;
  color: #999;
}

.tile-1 .t-icon {
  position: absolute;
  top: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.tile-1 .t-icon span {
  position: absolute;
  z-index: 8;
  top: -60px;
  width: 120px;
  height: 120px;
  line-height: 120px;
  border-radius: 50%;
}

.tile-1 .t-icon i {
  position: relative;
  z-index: 9;
  font-size: 1.75rem;
  color: #fff;
}

.tile-1 .t-icon.left {
  left: 0;
}

.tile-1 .t-icon.left span {
  left: -60px;
}

.tile-1 .t-icon.left i {
  padding-right: 15px;
}

.tile-1 .t-icon.right {
  right: 0;
}

.tile-1 .t-icon.right span {
  right: -60px;
}

.tile-1 .t-icon.right i {
  padding-left: 15px;
  color: rgba(255, 255, 255, 0.9);
}

.tile-2 {
  border: none !important;
}

.tile-2 h6 {
  color: rgba(255, 255, 255, 0.7);
}

.tile-2 .t-icon i {
  position: absolute;
  z-index: 9;
  bottom: -15px;
  right: 10px;
  font-size: 6rem;
  color: rgba(0, 0, 0, 0.1);
}

.tile-2 .t-icon.left i {
  left: 10px;
}

.tile-2 .t-icon.right i {
  right: 10px;
}

.tile-3 .t-icon i {
  position: absolute;
  z-index: 9;
  top: 20px;
  line-height: 1;
  font-size: 4rem;
  color: #ddd;
}

.tile-3 .t-icon.left i {
  left: 15px;
}

.tile-3 .t-icon.right i {
  right: 15px;
}

.tile-4 .t-icon {
  position: absolute;
  z-index: 9;
  top: 20px;
  width: 60px;
  height: 60px;
  line-height: 65px;
  background-color: #e8ebf0;
  font-size: 2rem;
  color: #818a91;
  text-align: center;
  border-radius: 50%;
}

.tile-4 .t-icon.left {
  left: 15px;
}

.tile-4 .t-icon.right {
  right: 15px;
}

/* User cards */
.user-1 {
  text-align: center;
}

.user-1 .u-img {
  margin-bottom: -32px;
  height: 170px;
}

.user-1 .avatar {
  margin: 0 auto 1rem auto;
}

.user-1 .u-content {
  padding: 0 1.25rem 1.25rem;
}

.user-1 .u-counters {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.user-1 .u-counters .uc-item {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}

.user-1 .u-counters .uc-item:last-child {
  border-right: none;
}

.user-1 .u-counters .uc-item a {
  display: block;
  padding: 1rem 0 0.75rem;
  width: 100%;
  height: 100%;
}

.user-1 .u-counters .uc-item a:focus,
.user-1 .u-counters .uc-item a:hover {
  background-color: #f5f5f5;
}

.user-1 .u-counters .uc-item strong {
  display: block;
  line-height: 1;
  font-size: 1.25rem;
}

.user-1 .u-counters .uc-item span {
  font-size: 0.75rem;
  color: #999;
  text-transform: uppercase;
}

.user-2 .u-img {
  position: relative;
  height: 210px;
}

.user-2 .u-content {
  padding: 1.25rem;
}

.user-2 .u-content .media-body {
  padding-top: 3px;
}

.user-2 .u-categories {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
}

.user-2 .u-categories .tag {
  display: inline-block;
  margin-right: 0.5rem;
  padding: 0.4em 0.8em;
  text-transform: uppercase;
}

.user-2 .u-counters {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

.user-2 .u-counters .uc-item {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.user-2 .u-counters .uc-item:last-child {
  border-right: none;
}

.user-2 .u-counters .uc-item a {
  display: block;
  padding: 0.5rem 0;
  width: 100%;
  height: 100%;
}

.user-2 .u-counters .uc-item i {
  margin-right: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
}

.user-3 {
  padding-top: 174px;
  background-color: #eee;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
}

.user-3:before {
  content: "";
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.user-3 .u-content {
  position: relative;
  z-index: 10;
  padding: 1.25rem 1.25rem 3rem;
}

.user-3 .u-counters {
  position: relative;
  z-index: 10;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.user-3 .u-counters .uc-item a {
  display: block;
  padding: 1rem 0 0.75rem;
  width: 100%;
  height: 100%;
}

.user-3 .u-counters .uc-item strong {
  display: block;
  line-height: 1;
  font-size: 1.25rem;
}

.user-3 .u-counters .uc-item span {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.9);
  text-transform: uppercase;
}

.user-4 {
  padding: 3rem 1.25rem;
  background-color: #eee;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
}

.user-4:before {
  content: "";
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.user-4 .u-content {
  position: relative;
  z-index: 10;
}

.user-4 .avatar {
  display: inline-block;
}

.user-5 {
  padding: 3rem 1.25rem;
  text-align: center;
}

.user-5 .avatar {
  display: inline-block;
}

/* Write something */
.write-something textarea {
  padding: 1rem 1.25rem;
  width: 100%;
  background: transparent;
  border: none;
  resize: none;
}

.write-something a {
  display: inline-block;
  margin-right: 1.5rem;
  padding: 8px 0 4px;
}

.write-something a i {
  font-size: 1.25em;
}

/* =================================================================
  Pages
================================================================= */

/* Auth */
.auth-bg {
  background-color: #212330;
}

.auth {
  color: #fff;
}

.auth .auth-header {
  margin-bottom: 6rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: rgba(255, 255, 255, 0.05);
  text-align: center;
}

.auth .auth-header h6 {
  font-weight: normal;
  color: rgba(255, 255, 255, 0.7);
}

.auth .form-control {
  background-color: rgba(255, 255, 255, 0.8);
  border-color: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.auth .form-control:focus {
  background-color: rgba(255, 255, 255, 0.9);
}

.auth .input-group-addon {
  padding: 0 0.75rem;
  font-size: 1.25rem;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
}

/* Error */
.error-message {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.error-message h1 {
  position: relative;
  font-size: 90px;
  height: 300px;
  line-height: 300px;
}

.error-message h1 span {
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -150px;
  width: 300px;
  height: 300px;
  line-height: 300px;
  font-size: 300px;
  color: rgba(255, 255, 255, 0.1);
}

.error-message .error-message-text {
  color: rgba(255, 255, 255, 0.7);
}

/* Profile */
.profile-header .profile-header-cover {
  height: 250px;
  background-color: #f3f7fc;
}

.profile-header-counters {
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.profile-header-counters a {
  display: block;
  padding: 0.75rem 1.25rem;
  text-align: center;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}

.profile-card {
  margin-top: -150px;
}

.profile-card .profile-avatar {
  padding: 0.5rem 0.5rem 0;
}

.profile-card .profile-avatar img {
  width: 100%;
}

.profile-card h4 {
  font-weight: bold;
}

.profile-card .list-group {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.profile-card .list-group-item {
  border: none;
}

.profile-tabs .nav-link {
  padding: 1rem 1.5rem;
}

.stream-item {
  padding: 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.stream-item:last-child {
  border-bottom: none;
}

.stream-item.media {
  margin-top: 0;
}

.stream-item .media-heading {
  margin-top: 0.5rem;
}

.stream-item .media-heading span {
  font-weight: normal;
}

.stream-item .stream-img {
  display: inline-block;
  float: left;
  margin-right: 1rem;
  max-width: 200px;
  height: auto;
}

.stream-item .stream-meta {
  color: #777;
}

.watermark {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stream-item .stream-body {
  margin-top: 1rem;
}

.stream-item .stream-body p {
  margin-bottom: 0;
}

/* Sign form */
.sign-form {
  padding-top: 100px;
}

.sign-form .box {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 1px 1px #ddd;
  border-radius: 5px;
}

.sign-form .form-material .form-control {
  padding: 0.75rem 1.5rem;
}

/* =================================================================
  Apps
================================================================= */

/* Mail */
.mail-items td {
  position: relative;
  vertical-align: middle;
}

.mail-items .mail-item-sender {
  padding-left: 25px;
}

.mail-items .mail-item-sender .avatar {
  display: inline-block;
}

.mail-items .mail-item-important {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 20px;
  line-height: 55px;
  color: #aaa;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.mail-items .mail-item-important:hover {
  color: #777;
}

.mail-items tr:hover .mail-item-important,
.mail-items tr.important .mail-item-important {
  opacity: 1;
}

.mail-items tr.important {
  background-color: #f9e0df;
}

.mail-items tr.important:hover {
  background-color: #fdd9d7;
}

.mail-items tr.important .mail-item-important {
  color: #602d91;
}

.mail-items tr.unread {
  font-weight: bold;
}

.mail-items .mail-item-attachment {
  width: 20px;
}

.mail-items .mail-item-time {
  width: 100px;
  text-align: right;
}

.mail-items .mail-item-checkbox {
  width: 50px;
  text-align: center;
}

.mail-items a {
  color: #333;
}

/* =================================================================
  Forms
================================================================= */

/* Material*/
.form-material .form-control {
  padding: 0.75rem 0;
  background-color: rgba(0, 0, 0, 0);
  background-position: center bottom, center calc(99%);
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  transition: background 0s ease-out 0s;
}

.form-material .form-control,
.form-material .form-control.focus,
.form-material .form-control:focus {
  background-image: linear-gradient(#a567e2, #a567e2),
    linear-gradient(rgba(0, 0, 0, 0.125), rgba(0, 0, 0, 0.125));
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  float: none;
}

.form-material.material-primary .form-control,
.form-material.material-primary .form-control.focus,
.form-material.material-primary .form-control:focus {
  background-image: linear-gradient(#3e70c9, #3e70c9),
    linear-gradient(rgba(0, 0, 0, 0.125), rgba(0, 0, 0, 0.125));
}

.form-material.material-info .form-control,
.form-material.material-info .form-control.focus,
.form-material.material-info .form-control:focus {
  background-image: linear-gradient(#20b9ae, #20b9ae),
    linear-gradient(rgba(0, 0, 0, 0.125), rgba(0, 0, 0, 0.125));
}

.form-material.material-success .form-control,
.form-material.material-success .form-control.focus,
.form-material.material-success .form-control:focus {
  background-image: linear-gradient(#43b968, #43b968),
    linear-gradient(rgba(0, 0, 0, 0.125), rgba(0, 0, 0, 0.125));
}

.form-material.material-warning .form-control,
.form-material.material-warning .form-control.focus,
.form-material.material-warning .form-control:focus {
  background-image: linear-gradient(#f59345, #f59345),
    linear-gradient(rgba(0, 0, 0, 0.125), rgba(0, 0, 0, 0.125));
}

.form-material.material-danger .form-control,
.form-material.material-danger .form-control.focus,
.form-material.material-danger .form-control:focus {
  background-image: linear-gradient(#f44236, #f44236),
    linear-gradient(rgba(0, 0, 0, 0.125), rgba(0, 0, 0, 0.125));
}

.form-material .form-control.focus,
.form-material .form-control:focus {
  background-size: 100% 2px, 100% 1px;
  outline: 0 none;
  transition-duration: 0.3s;
}

/* Select2 */
.select2-container--default .select2-selection--single {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

.select2-container--default .select2-selection--multiple {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 0;
}

/* Tags Input */
.bootstrap-tagsinput {
  width: 100%;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

.bootstrap-tagsinput .label-info {
  background-color: #20b9ae;
  display: inline-block;
  padding: 4px 7px 5px;
}

/* Multi Select */
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.ms-container .ms-list {
  border-radius: 0;
  box-shadow: none;
}

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection {
  padding: 6px 10px;
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
  background: #20b9ae;
}

/* TouchSpin */
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-top-right-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  margin-top: -3px;
  border-bottom-right-radius: 0;
}

/* Datepicker */
.datepicker td,
.datepicker th {
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 0;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-image: none;
  background: #43b968;
  color: #ffffff;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-image: none;
  background: #3e70c9;
  color: #ffffff;
}

/* Date Range Picker */
.daterangepicker .input-mini {
  border-radius: 0 !important;
}

.daterangepicker td,
.daterangepicker th {
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 0 !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #3e70c9;
}

.ranges li {
  background: #f5f5f5;
  border-radius: 0 !important;
  color: #333;
}

.ranges li:hover {
  background: #eee;
  border: 1px solid #eee;
  color: #333;
}

.ranges li.active {
  background: #3e70c9;
  border: 1px solid #3e70c9;
}

/* Jquery Steps */
.wizard-steps {
  display: table;
  width: 100%;
}

.wizard-steps > li {
  display: table-cell;
  padding: 20px 20px;
  background: #f5f5f5;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-right: none;
}

.wizard-steps > li > i {
  float: right;
  font-size: 1.25rem;
}

.wizard-steps > li:last-child {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.wizard-steps > li.current,
.wizard-steps > li.done {
  background: #3e70c9;
  color: #fff !important;
}

.wizard-steps > li.done {
  background: #43b968;
}

.wizard-steps > li.error {
  background: #f44236;
}

.wizard-content {
  padding: 2rem;
  border-color: rgba(0, 0, 0, 0.125);
  border-top: none;
  margin-bottom: 1rem;
}

/* =================================================================
  Tables
================================================================= */

/* Data Tables */
table.dataTable {
  border-spacing: 0;
}

table.dataTable thead th,
table.dataTable thead td {
  border-bottom: 1px solid #ddd;
}

table.dataTable tfoot th,
table.dataTable tfoot td {
  border-top: 1px solid #ddd;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  display: none;
}

table.dataTable thead th {
  position: relative;
  background-image: none !important;
}

table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
  position: absolute;
  top: 12px;
  right: 8px;
  display: block;
  font-family: "FontAwesome";
}

table.dataTable thead th.sorting:after {
  content: "\f0dc";
  color: #333;
  font-size: 1em;
  padding-top: 0.12em;
}

table.dataTable thead th.sorting_asc:after {
  content: "\f0de";
}

table.dataTable thead th.sorting_desc:after {
  content: "\f0dd";
}

/* Editable Tables */
.editable-table + input.error {
  border: 1px solid #f44236;
  outline: 0;
  outline-offset: 0;
}

.editable-table + input,
.editable-table + input:focus,
#editable-datatable_wrapper + input:focus {
  border: 1px solid #20b9ae !important;
  outline: 0 !important;
  outline-offset: 0 !important;
}

.editable-table td:focus {
  outline: 0;
}

/* Responsive Tables */
table.focus-on tbody tr.focused th,
table.focus-on tbody tr.focused td {
  background-color: #3e70c9;
  color: #fff;
}

/* jsGrid Tables */
.jsgrid .table {
  margin-bottom: 0;
}

.jsgrid-header-row .jsgrid-header-sort {
  background-color: #3e70c9;
  color: #fff;
}

.jsgrid-header-sort-asc:before {
  margin-right: 10px;
  padding-top: 8px;
  border-color: transparent transparent #fff;
}

/* =================================================================
  Extra
================================================================= */

/* Loading progress */
#nprogress .spinner {
  width: 140px;
  right: auto;
  left: 50%;
  top: 20px;
  margin-left: -73px;
  padding: 8px 15px;
  background: #fff;
  color: #333;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

#nprogress .spinner:after {
  content: "Loading...";
  display: inline-block;
  position: absolute;
  top: 7px;
  left: 48px;
}

/* Comments */
.management {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.management .m-item {
  position: relative;
  padding: 1.5rem 1.5rem 1.5rem 3.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.management .m-item:active,
.management .m-item:hover {
  background-color: #f5f5f5;
}

.management .m-item:last-child {
  border-bottom: none;
}

.management .m-item .mi-checkbox {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
}

.management .m-item .mi-title {
  margin-bottom: 0.5rem;
  color: #999;
}

.management .m-item .mi-title a {
  font-weight: bold;
}

.management .m-item .mi-text {
  margin-bottom: 1rem;
  color: #999;
  text-align: justify;
}

.management .m-item .btn {
  margin-right: 0.5rem;
}

/* Image Crop */
.jcrop-holder #preview-pane {
  display: block;
  position: absolute;
  z-index: 10;
  top: 0;
  right: -280px;
}

#preview-pane .preview-container {
  width: 250px;
  height: 170px;
  overflow: hidden;
}

/* Price card */
.price-card {
  margin-bottom: 0;
}

.price-card .price-card-header sup {
  position: relative;
  top: -1em;
  font-size: 75%;
}

.price-card .price-card-header .text-big {
  font-size: 2em;
  font-weight: bold;
}

.price-card .price-card-header .text-small {
  font-size: 0.75rem;
}

.price-card .price-card-list li {
  display: block;
  position: relative;
  padding: 0.75rem 1.25rem;
}

.price-card .price-card-list li:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.price-card .price-card-list li:last-child:after {
  border: none;
}

/* Search */
.search-header {
  background: #fff;
  padding: 2rem 0;
}

.search-header .sh-search-field,
.search-header .sh-search-submit {
  height: 54px;
}

.search-header .sh-search-submit {
  font-size: 18px;
  font-weight: bold;
}

.search-tabs {
  border-top: 1px solid #ddd;
  background: #f5f5f5;
}

.search-tabs .st-item a {
  display: block;
  padding: 1rem 0;
  width: 100%;
  height: 100%;
  text-align: center;
  border-right: 1px solid #ddd;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.search-tabs .st-item:last-child a {
  border-right: none;
}

.search-tabs .st-item a:focus,
.search-tabs .st-item a:hover,
.search-tabs .st-item a:active {
  background-color: #eee;
  color: #333;
}

.search-results h6 {
  margin-bottom: 0.25rem;
}

.search-results p {
  margin-bottom: 0.25rem;
}

.search-results .sr-item {
  margin-bottom: 1.5rem;
}

.search-results .sr-item:last-child {
  margin-bottom: 0;
}

/* Timeline */
.timeline .tl-item {
  display: block;
}

.timeline .tl-item:after {
  content: "";
  display: table;
  clear: both;
}

.timeline.timeline-center .tl-item {
  margin-left: 50%;
}

.timeline.timeline-center .tl-left {
  margin-left: 0;
  margin-right: 50%;
}

.timeline .tl-wrap {
  position: relative;
  padding: 1.25rem;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.125);
  border-width: 0 0 0 2px;
}

.timeline.timeline-center .tl-left .tl-wrap {
  float: right;
  margin-right: -2px;
  border-left-width: 0;
  border-right-width: 2px;
}

.timeline .tl-wrap:before {
  content: "";
  position: absolute;
  top: 1.25rem;
  left: -8px;
  margin-top: 18px;
  width: 14px;
  height: 14px;
  background: #fff;
  border-width: 2px;
  border-style: solid;
  border-color: inherit;
  border-radius: 50%;
}

.timeline.timeline-center .tl-left .tl-wrap:before {
  left: auto;
  right: -8px;
}

.timeline.timeline-center .tl-date {
  font-size: 90%;
}

.timeline.timeline-center .tl-left .tl-date {
  text-align: right;
}

.timeline .tl-content {
  display: inline-block;
  position: relative;
  margin-bottom: 0.5rem;
}

.timeline .tl-left .arrow.right {
  display: none;
}

.timeline.timeline-center .tl-left .arrow.right {
  display: block;
}

.timeline.timeline-center .tl-left .arrow.left {
  display: none;
}

.timeline .arrow {
  position: absolute;
  z-index: 10;
  top: 15px;
}

.timeline .arrow:after,
.timeline .arrow:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-width: 9px;
  border-color: transparent;
  border-style: solid;
}

.timeline .arrow:after {
  border-width: 8px;
}

.timeline .arrow.right {
  right: 0;
}

.timeline .arrow.right:before {
  border-left-color: rgba(0, 0, 0, 0.125);
  border-right-color: transparent;
  border-right-width: 0;
}

.timeline .arrow.right:after {
  top: 1px;
  left: 0;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: inherit;
  border-right-width: 0;
}

.timeline .arrow.left {
  left: -9px;
}

.timeline .arrow.left:before {
  border-right-color: rgba(0, 0, 0, 0.125);
  border-left-color: transparent;
  border-left-width: 0;
}

.timeline .arrow.left:after {
  top: 1px;
  left: 1px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: inherit;
  border-left-width: 0;
}

/* =================================================================
  Frontend
================================================================= */

.frontend-wrapper {
  max-width: 1370px;
  margin: 0 auto;
}

.frontend-max-width .container-fluid {
  max-width: 1170px;
}

/* Block 1 */
.block-1 {
  padding: 70px 0 50px;
}

.block-1 .b-item {
  margin-bottom: 2rem;
  overflow: hidden;
  text-align: center;
}

.block-1 .b-item .bi-image {
  position: relative;
  margin-bottom: 20px;
  height: 200px;
}

.block-1 .b-item .bi-image:before {
  content: "";
  position: absolute;
  z-index: 9;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.4);
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.block-1 .b-item:hover .bi-image:before {
  opacity: 1;
}

.block-1 .b-item .bi-image:after {
  content: "";
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
  border-width: 30px 30px 0 0;
  border-style: solid;
  border-color: #f44236 #fff;
}

.block-1 .b-item .btn {
  position: relative;
  z-index: 10;
  margin-top: -100px;
}

.block-1 .b-item:hover .btn {
  margin-top: 84px;
}

.block-1 .b-item .bi-title {
  font-size: 1.125rem;
  text-align: center;
}

/* Block 2 */
.block-2 {
  padding: 25px 0;
  background-color: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.block-2 .b-item {
  position: relative;
  margin: 35px 0;
  padding-left: 80px;
}

.block-2 .b-item .bi-icon {
  position: absolute;
  top: -5px;
  left: 0;
  font-size: 4rem;
  color: rgba(255, 255, 255, 0.4);
}

.block-2 .b-item .bi-title {
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  font-weight: bold;
}

.block-2 .b-item .bi-text {
  color: rgba(255, 255, 255, 0.8);
}

/* Block 3 */
.block-3 {
  padding: 70px 0 100px;
}

.block-3 .b-item {
  position: relative;
  padding: 90px 0 0 40px;
}

.block-3 .b-item .bi-number {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  line-height: 1;
  font-family: "Exo 2", sans-serif;
  font-weight: bold;
  font-size: 119px;
  color: #ddd;
}

.block-3 .b-item .bi-title {
  position: relative;
  z-index: 10;
  margin-bottom: 10px;
  font-size: 1.125rem;
}

.block-3 .b-item .bi-text {
  color: #999;
}

/* Block-4 */
.block-4 {
  padding: 40px 0;
  text-align: center;
}

.block-4 .b-to-top {
  display: block;
  margin: 0 auto;
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: #999;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.block-4 .b-to-top:hover,
.block-4 .b-to-top:focus {
  text-decoration: none;
  color: #333;
}

/* Block 5 */
.block-5 {
  position: relative;
  padding: 30px 0;
}

.block-5:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
}

.block-5 .b-content {
  position: relative;
  z-index: 10;
  color: #fff;
}

.block-5 .b-logo {
  margin-bottom: 150px;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
}

.block-5 .b-title {
  margin-bottom: 2rem;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
}

.block-5 .b-text {
  margin-bottom: 150px;
  font-size: 1.125rem;
  color: rgba(255, 255, 255, 0.9);
}

/* Block 6 */
.block-6 {
  padding: 100px 0 120px;
}

.block-6 .b-item .bi-icon {
  margin-bottom: 0.5rem;
  font-size: 40px;
  color: #bbb;
}

.block-6 .b-item .bi-title {
  font-size: 1.125rem;
}

/* Block 7 */
.block-7 .b-item {
  position: relative;
  overflow: hidden;
}

.block-7 .b-item img {
  position: relative;
  z-index: 9;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.block-7 .b-item:hover img {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.block-7 .b-item .bi-content {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 100px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.block-7 .b-item .bi-content:before {
  content: "";
  display: inline-block;
  height: 100%;
  width: 1px;
  vertical-align: middle;
}

.block-7 .b-item .bi-content span {
  display: inline-block;
  position: relative;
  z-index: 10;
  max-width: 98%;
  vertical-align: middle;
}

.block-7 .b-item .bi-content h6 {
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.block-7 .b-item .bi-content h4 {
  line-height: 30px;
  font-weight: normal;
  text-transform: uppercase;
}

.block-7 .b-item .bi-content .separator {
  width: 65px;
  height: 1px;
  background: #fff;
  margin: 2rem 0;
}

.block-7 .b-item .bi-content p {
  margin-bottom: 2rem;
  font-size: 1.125rem;
}

.block-7 .b-item:hover .bi-content {
  opacity: 1;
}

/* Block 8 */
.block-8 {
  padding: 100px 0;
}

.block-8 h4 {
  line-height: 30px;
  font-weight: normal;
  text-transform: uppercase;
}

.block-8 .separator {
  width: 65px;
  height: 1px;
  background: #333;
  margin: 2rem 0;
}

.block-8 p {
  font-size: 1.125rem;
  color: #999;
}

.block-8 .b-img {
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
}

.block-8 .b-img .bi-title {
  position: absolute;
  bottom: -40px;
  left: 0;
  padding: 0.75rem 1.25rem;
  width: 100%;
  height: 40px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.block-8 .b-img:hover .bi-title {
  bottom: 0;
}

/* Block 9 */
.block-9 {
  position: relative;
  padding: 120px 0;
  color: #fff;
}

.block-9:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
}

.block-9 .b-number {
  font-size: 5rem;
  margin-bottom: 1rem;
}

.block-9 .b-title {
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  text-transform: uppercase;
}

.block-9 .b-text {
  padding-right: 100px;
  color: rgba(255, 255, 255, 0.8);
}

/* Block 10 */
.block-10 {
  padding: 120px 0;
  color: #fff;
}

/* =================================================================
  Charts
================================================================= */

.chart-container {
  width: 100%;
  height: 350px;
}

.chart-placeholder {
  width: 100%;
  height: 100%;
}

.flotTip {
  z-index: 100;
  padding: 8px 12px;
  background-color: #333;
  font-size: 13px;
  color: #fff;
  opacity: 0.8;
}

.jqstooltip {
  background-color: #333 !important;
  padding: 5px !important;
  width: auto !important;
  height: auto !important;
  border: none !important;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: #43b968;
}

.ct-series-a .ct-slice-pie,
.ct-series-a .ct-area {
  fill: #43b968;
}

.ct-series-b .ct-point,
.ct-series-b .ct-line,
.ct-series-b .ct-bar,
.ct-series-b .ct-slice-donut {
  stroke: #3e70c9;
}

.ct-series-b .ct-slice-pie,
.ct-series-b .ct-area {
  fill: #3e70c9;
}

.ct-series-c .ct-point,
.ct-series-c .ct-line,
.ct-series-c .ct-bar,
.ct-series-c .ct-slice-donut {
  stroke: #f59345;
}

.ct-series-c .ct-slice-pie,
.ct-series-c .ct-area {
  fill: #f59345;
}

.ct-series-d .ct-point,
.ct-series-d .ct-line,
.ct-series-d .ct-bar,
.ct-series-d .ct-slice-donut {
  stroke: #f44236;
}

.ct-series-d .ct-slice-pie,
.ct-series-d .ct-area {
  fill: #f44236;
}

.ct-series-e .ct-point,
.ct-series-e .ct-line,
.ct-series-e .ct-bar,
.ct-series-e .ct-slice-donut {
  stroke: #a567e2;
}

.ct-series-e .ct-slice-pie,
.ct-series-e .ct-area {
  fill: #a567e2;
}

.ct-series-f .ct-point,
.ct-series-f .ct-line,
.ct-series-f .ct-bar,
.ct-series-f .ct-slice-donut {
  stroke: #20b9ae;
}

.ct-series-f .ct-slice-pie,
.ct-series-f .ct-area {
  fill: #20b9ae;
}

.chartist-light .ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
}

.chartist-light .ct-label {
  fill: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.2);
}

.chartist-animated .ct-series-a .ct-line {
  fill: none;
  stroke-width: 4px;
  stroke-dasharray: 5px;
  -webkit-animation: dashoffset 1s linear infinite;
  -o-animation: dashoffset 1s linear infinite;
  animation: dashoffset 1s linear infinite;
}

.chartist-animated .ct-series-b .ct-point {
  -webkit-animation: bouncing-stroke 0.5s ease infinite;
  -o-animation: bouncing-stroke 0.5s ease infinite;
  animation: bouncing-stroke 0.5s ease infinite;
}

.chartist-animated .ct-series-c .ct-point {
  -webkit-animation: exploding-stroke 1s ease-out infinite;
  -o-animation: exploding-stroke 1s ease-out infinite;
  animation: exploding-stroke 1s ease-out infinite;
}

.chart-easy {
  position: relative;
  width: 100px;
  height: 100px;
  text-align: center;
}

.chart-easy canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.chart-easy span {
  display: block;
  line-height: 100px;
  z-index: 2;
  font-size: 18px;
  color: #2b2b2b;
}

/* =================================================================
  Calendar
================================================================= */

#external-events .fc-event {
  margin-top: 1rem;
}

#external-events .fc-event {
  display: inline-block;
  margin-right: 15px;
}

.fc-toolbar {
  margin-bottom: 0.5rem;
  margin-top: 2rem;
}

.fc-toolbar h2 {
  font-size: 18px;
  line-height: 30px;
  text-transform: uppercase;
}

.fc-view {
  margin-top: 1rem;
}

.fc-day {
  background: #fff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc th.fc-widget-header {
  background: #eee;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  padding: 7px 0px;
  text-transform: uppercase;
}

.fc-button {
  background: #fff;
  text-transform: capitalize;
  text-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}

.fc-state-hover {
  background: #f5f5f5;
}

.fc-unthemed .fc-today {
  background: #d9f1e1 !important;
}

.fc-state-highlight {
  background: #f0f0f0;
}

.fc-cell-overlay {
  background: #f0f0f0;
}

.fc-unthemed .fc-today {
  background: #fff;
}

.fc-event {
  border-radius: 0px;
  border: none;
  cursor: move;
  font-size: 13px;
  margin: 1px -1px 0 -1px;
  padding: 6px 10px;
  text-align: center;
  background: #3e70c9;
  color: #fff !important;
}

.calendar-event {
  cursor: move;
  margin: 10px 5px 0 0;
  padding: 6px 10px;
  display: inline-block;
  color: #fff;
  min-width: 140px;
  text-align: center;
  background: #03a9f3;
}

.calendar-event a {
  float: right;
  opacity: 0.6;
  font-size: 10px;
  margin: 4px 0 0 10px;
  color: #fff;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 5px;
}

.fc-basic-view td.fc-day-number {
  padding-right: 5px;
}

.fc-state-active,
.fc-state-down {
  background-color: #eee;
  box-shadow: none;
}

#calendar button {
  border-radius: 0 !important;
}

/* =================================================================
  Maps
================================================================= */

.box-arrow {
  display: inline-block;
  position: relative;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  text-align: center;
}

.box-arrow span {
  position: absolute;
  bottom: -9px;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top-width: 10px;
  border-top-style: solid;
  border-top-color: inherit;
}

/* =================================================================
  Demo
================================================================= */

.demo-chart {
  position: relative;
  left: 0;
  right: -10px;
}

.demo-chart-2 {
  height: 245px !important;
}

.demo-progress {
  padding-top: 70px;
}

/* =================================================================
  Other
================================================================= */

/* Arrows */
.arrow:before,
.arrow:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.arrow-top:before {
  top: -9px;
  left: 50%;
  margin-left: -9px;
  border-width: 0 9px 9px;
  border-bottom-color: #ccc;
}

.arrow-top:after {
  top: -8px;
  left: 50%;
  margin-left: -8px;
  border-width: 0 8px 8px;
  border-bottom-color: #fff;
}

.arrow-bottom:before {
  bottom: -9px;
  left: 50%;
  margin-left: -9px;
  border-width: 9px 9px 0;
  border-top-color: #ccc;
}

.arrow-bottom:after {
  bottom: -8px;
  left: 50%;
  margin-left: -8px;
  border-width: 8px 8px 0;
  border-top-color: #fff;
}

.arrow-left:before {
  top: 50%;
  left: -9px;
  margin-top: -9px;
  border-width: 9px 9px 9px 0;
  border-right-color: #ccc;
}

.arrow-left:after {
  top: 50%;
  left: -8px;
  margin-top: -8px;
  border-width: 8px 8px 8px 0;
  border-right-color: #fff;
}

.arrow-right:before {
  top: 50%;
  right: -9px;
  margin-top: -9px;
  border-width: 9px 0 9px 9px;
  border-left-color: #ccc;
}

.arrow-right:after {
  top: 50%;
  right: -8px;
  margin-top: -8px;
  border-width: 8px 0 8px 8px;
  border-left-color: #fff;
}

.arrow-top.left:before,
.arrow-bottom.left:before {
  left: 10px;
  margin-left: 0;
}

.arrow-top.left:after,
.arrow-bottom.left:after {
  left: 11px;
  margin-left: 0;
}

.arrow-top.right:before,
.arrow-bottom.right:before {
  left: auto;
  right: 10px;
  margin-left: 0;
}

.arrow-top.right:after,
.arrow-bottom.right:after {
  left: auto;
  right: 11px;
  margin-left: 0;
}

.arrow-left.top:before,
.arrow-right.top:before {
  top: 10px;
  margin-top: 0;
}

.arrow-left.top:after,
.arrow-right.top:after {
  top: 11px;
  margin-top: 0;
}

.arrow-left.bottom:before,
.arrow-right.bottom:before {
  top: auto;
  bottom: 10px;
  margin-top: 0;
}

.arrow-left.bottom:after,
.arrow-right.bottom:after {
  top: auto;
  bottom: 11px;
  margin-top: 0;
}

.arrow-top.arrow-primary:before,
.arrow-top.arrow-primary:after {
  border-bottom-color: #3e70c9;
}

.arrow-bottom.arrow-primary:before,
.arrow-bottom.arrow-primary:after {
  border-top-color: #3e70c9;
}

.arrow-left.arrow-primary:before,
.arrow-left.arrow-primary:after {
  border-right-color: #3e70c9;
}

.arrow-right.arrow-primary:before,
.arrow-right.arrow-primary:after {
  border-left-color: #3e70c9;
}

.arrow-top.arrow-success:before,
.arrow-top.arrow-success:after {
  border-bottom-color: #43b968;
}

.arrow-bottom.arrow-success:before,
.arrow-bottom.arrow-success:after {
  border-top-color: #43b968;
}

.arrow-left.arrow-success:before,
.arrow-left.arrow-success:after {
  border-right-color: #43b968;
}

.arrow-right.arrow-success:before,
.arrow-right.arrow-success:after {
  border-left-color: #43b968;
}

.arrow-top.arrow-info:before,
.arrow-top.arrow-info:after {
  border-bottom-color: #20b9ae;
}

.arrow-bottom.arrow-info:before,
.arrow-bottom.arrow-info:after {
  border-top-color: #20b9ae;
}

.arrow-left.arrow-info:before,
.arrow-left.arrow-info:after {
  border-right-color: #20b9ae;
}

.arrow-right.arrow-info:before,
.arrow-right.arrow-info:after {
  border-left-color: #20b9ae;
}

.arrow-top.arrow-warning:before,
.arrow-top.arrow-warning:after {
  border-bottom-color: #f59345;
}

.arrow-bottom.arrow-warning:before,
.arrow-bottom.arrow-warning:after {
  border-top-color: #f59345;
}

.arrow-left.arrow-warning:before,
.arrow-left.arrow-warning:after {
  border-right-color: #f59345;
}

.arrow-right.arrow-warning:before,
.arrow-right.arrow-warning:after {
  border-left-color: #f59345;
}

.arrow-top.arrow-danger:before,
.arrow-top.arrow-danger:after {
  border-bottom-color: #f44236;
}

.arrow-bottom.arrow-danger:before,
.arrow-bottom.arrow-danger:after {
  border-top-color: #f44236;
}

.arrow-left.arrow-danger:before,
.arrow-left.arrow-danger:after {
  border-right-color: #f44236;
}

.arrow-right.arrow-danger:before,
.arrow-right.arrow-danger:after {
  border-left-color: #f44236;
}

.arrow-top.arrow-purple:before,
.arrow-top.arrow-purple:after {
  border-bottom-color: #a567e2;
}

.arrow-bottom.arrow-purple:before,
.arrow-bottom.arrow-purple:after {
  border-top-color: #a567e2;
}

.arrow-left.arrow-purple:before,
.arrow-left.arrow-purple:after {
  border-right-color: #a567e2;
}

.arrow-right.arrow-purple:before,
.arrow-right.arrow-purple:after {
  border-left-color: #a567e2;
}

.arrow-top.arrow-outline-primary:before {
  border-bottom-color: #3e70c9;
}

.arrow-top.arrow-outline-primary:after {
  border-bottom-color: #fff;
}

.arrow-bottom.arrow-outline-primary:before {
  border-top-color: #3e70c9;
}

.arrow-bottom.arrow-outline-primary:after {
  border-top-color: #fff;
}

.arrow-left.arrow-outline-primary:before {
  border-right-color: #3e70c9;
}

.arrow-left.arrow-outline-primary:after {
  border-right-color: #fff;
}

.arrow-right.arrow-outline-primary:before {
  border-left-color: #3e70c9;
}

.arrow-right.arrow-outline-primary:after {
  border-left-color: #fff;
}

.arrow-top.arrow-outline-success:before {
  border-bottom-color: #43b968;
}

.arrow-top.arrow-outline-success:after {
  border-bottom-color: #fff;
}

.arrow-bottom.arrow-outline-success:before {
  border-top-color: #43b968;
}

.arrow-bottom.arrow-outline-success:after {
  border-top-color: #fff;
}

.arrow-left.arrow-outline-success:before {
  border-right-color: #43b968;
}

.arrow-left.arrow-outline-success:after {
  border-right-color: #fff;
}

.arrow-right.arrow-outline-success:before {
  border-left-color: #43b968;
}

.arrow-right.arrow-outline-success:after {
  border-left-color: #fff;
}

.arrow-top.arrow-outline-info:before {
  border-bottom-color: #20b9ae;
}

.arrow-top.arrow-outline-info:after {
  border-bottom-color: #fff;
}

.arrow-bottom.arrow-outline-info:before {
  border-top-color: #20b9ae;
}

.arrow-bottom.arrow-outline-info:after {
  border-top-color: #fff;
}

.arrow-left.arrow-outline-info:before {
  border-right-color: #20b9ae;
}

.arrow-left.arrow-outline-info:after {
  border-right-color: #fff;
}

.arrow-right.arrow-outline-info:before {
  border-left-color: #20b9ae;
}

.arrow-right.arrow-outline-info:after {
  border-left-color: #fff;
}

.arrow-top.arrow-outline-warning:before {
  border-bottom-color: #f59345;
}

.arrow-top.arrow-outline-warning:after {
  border-bottom-color: #fff;
}

.arrow-bottom.arrow-outline-warning:before {
  border-top-color: #f59345;
}

.arrow-bottom.arrow-outline-warning:after {
  border-top-color: #fff;
}

.arrow-left.arrow-outline-warning:before {
  border-right-color: #f59345;
}

.arrow-left.arrow-outline-warning:after {
  border-right-color: #fff;
}

.arrow-right.arrow-outline-warning:before {
  border-left-color: #f59345;
}

.arrow-right.arrow-outline-warning:after {
  border-left-color: #fff;
}

.arrow-top.arrow-outline-danger:before {
  border-bottom-color: #f44236;
}

.arrow-top.arrow-outline-danger:after {
  border-bottom-color: #fff;
}

.arrow-bottom.arrow-outline-danger:before {
  border-top-color: #f44236;
}

.arrow-bottom.arrow-outline-danger:after {
  border-top-color: #fff;
}

.arrow-left.arrow-outline-danger:before {
  border-right-color: #f44236;
}

.arrow-left.arrow-outline-danger:after {
  border-right-color: #fff;
}

.arrow-right.arrow-outline-danger:before {
  border-left-color: #f44236;
}

.arrow-right.arrow-outline-danger:after {
  border-left-color: #fff;
}

.arrow-top.arrow-outline-purple:before {
  border-bottom-color: #a567e2;
}

.arrow-top.arrow-outline-purple:after {
  border-bottom-color: #fff;
}

.arrow-bottom.arrow-outline-purple:before {
  border-top-color: #a567e2;
}

.arrow-bottom.arrow-outline-purple:after {
  border-top-color: #fff;
}

.arrow-left.arrow-outline-purple:before {
  border-right-color: #a567e2;
}

.arrow-left.arrow-outline-purple:after {
  border-right-color: #fff;
}

.arrow-right.arrow-outline-purple:before {
  border-left-color: #a567e2;
}

.arrow-right.arrow-outline-purple:after {
  border-left-color: #fff;
}

.arrow-top.arrow-sm:before {
  top: -6px;
  margin-left: -6px;
  border-width: 0 6px 6px;
}

.arrow-top.arrow-sm:after {
  top: -5px;
  margin-left: -5px;
  border-width: 0 5px 5px;
}

.arrow-top.arrow-lg:before {
  top: -12px;
  margin-left: -12px;
  border-width: 0 12px 12px;
}

.arrow-top.arrow-lg:after {
  top: -11px;
  margin-left: -11px;
  border-width: 0 11px 11px;
}

.arrow-bottom.arrow-sm:before {
  bottom: -6px;
  margin-left: -6px;
  border-width: 6px 6px 0;
}

.arrow-bottom.arrow-sm:after {
  bottom: -5px;
  margin-left: -5px;
  border-width: 5px 5px 0;
}

.arrow-bottom.arrow-lg:before {
  bottom: -12px;
  margin-left: -12px;
  border-width: 12px 12px 0;
}

.arrow-bottom.arrow-lg:after {
  bottom: -11px;
  margin-left: -11px;
  border-width: 11px 11px 0;
}

.arrow-left.arrow-sm:before {
  left: -6px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
}

.arrow-left.arrow-sm:after {
  left: -5px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
}

.arrow-left.arrow-lg:before {
  left: -12px;
  margin-top: -12px;
  border-width: 12px 12px 12px 0;
}

.arrow-left.arrow-lg:after {
  left: -11px;
  margin-top: -11px;
  border-width: 11px 11px 11px 0;
}

.arrow-right.arrow-sm:before {
  right: -6px;
  margin-top: -6px;
  border-width: 6px 0 6px 6px;
}

.arrow-right.arrow-sm:after {
  right: -5px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
}

.arrow-right.arrow-lg:before {
  right: -12px;
  margin-top: -12px;
  border-width: 12px 0 12px 12px;
}

.arrow-right.arrow-lg:after {
  right: -11px;
  margin-top: -11px;
  border-width: 11px 0 11px 11px;
}

/* Avatars */
.avatars .avatar {
  display: inline-block;
}

.avatar {
  position: relative;
  line-height: 1;
}

.avatar img {
  width: 100%;
  border-radius: 50%;
}

.avatar .status {
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  border-radius: 50%;
}

.avatar .status.top {
  top: -2px;
}

.avatar .status.left {
  left: 0;
}

.avatar .status.bottom {
  bottom: -2px;
}

.avatar .status.right {
  right: 0;
}

.avatar.box-32 .status {
  margin: 0;
}

.avatar.box-48 .status {
  margin: 2px;
}

.avatar.box-64 .status {
  margin: 4px;
}

.avatar.box-96 .status {
  margin: 9px;
}

.avatar.box-128 .status {
  margin: 14px;
}

.avatar.box-160 .status {
  margin: 19px;
}

/* Icon */
.icon-area div {
  overflow: hidden;
  line-height: 5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.icon-area div:hover {
  background-color: #f5f5f5;
}

.icon-area i,
.icon-area span {
  display: inline-block;
  width: 3rem;
  font-size: 2rem;
  vertical-align: middle;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.icon-area div:hover i,
.icon-area div:hover span {
  width: 4rem;
  font-size: 3rem;
}

/* Flags */
.flags .f-item {
  padding: 1rem;
  border: 1px solid #ddd;
  margin-bottom: 1rem;
}

.flags .f-item .name,
.flags .f-item .capital {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flags .f-item .code {
  display: block;
  font-size: 90%;
  color: #999;
}

.flags .f-item .capital {
  margin-bottom: 0.5rem;
}

.flags .f-item .flag-icon {
  font-size: 4rem;
}

/* Material design */
.material-design .site-header {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}

.material-design .site-sidebar {
  box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.05), 2px 0 8px 0 rgba(0, 0, 0, 0.1);
}

.material-design .site-sidebar .sidebar-menu > li.active > a:after {
  display: none;
}

.material-design .alert,
.material-design .box,
.material-design .card,
.material-design .dropdown-menu,
.material-design .nav-pills .nav-link.active {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}

.material-design .btn {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.material-design .btn:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 16px 0 rgba(0, 0, 0, 0.25);
}

.material-design .site-header .header-form .btn,
.material-design .input-group-btn .btn {
  box-shadow: none;
}

.material-design .modal-content {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.material-design #toast-container > div {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 2px 8px 0 rgba(0, 0, 0, 0.1) !important;
}

.material-design #toast-container > :hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 16px 0 rgba(0, 0, 0, 0.15) !important;
}

/*Theme-settings */
.theme-settings a {
  display: block;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.theme-settings a:hover,
.theme-settings a:focus {
  display: block;
  color: rgba(0, 0, 0, 0.9);
}

.theme-settings a img {
  margin-bottom: 0.5rem;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.theme-settings a:hover img {
  opacity: 0.7;
}

/* Footer */
.footer {
  /*border-top: 1px solid rgba(0, 0, 0, 0.125);*/
  color: #777;
  /*padding: 1rem 0;*/
}

.fixed-footer .footer {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}

@media (min-width: 768px) {
  .fixed-footer .footer {
    margin-left: 220px;
  }
}

/* Row */
.row-area {
  padding-right: 15px;
  padding-left: 15px;
}

.row-area .row {
  margin-top: 1rem;
}

.row-area .row > [class^="col-"] {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.125);
}

/* Titles */
.title-1 {
  text-align: center;
}

.title-1 span {
  display: inline-block;
  position: relative;
  padding: 0 1rem;
}

.title-1 span:before {
  content: "";
  position: absolute;
  z-index: 9;
  top: 15px;
  left: -50px;
  width: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
}

.title-1 span:after {
  content: "";
  position: absolute;
  z-index: 9;
  top: 15px;
  right: -50px;
  width: 50px;
  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: inherit;
}

/* Other */
.dropdown-static-opened {
  display: block;
  position: static;
}

.static-modal.modal {
  display: block;
  position: relative;
  z-index: 1;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

.static-modal .modal-dialog {
  margin: 0;
  width: auto;
  max-width: none;
}

/* =================================================================
  Bootstrap
================================================================= */

/* Alert */
.alert {
  border-radius: 0;
  border-color: rgba(0, 0, 0, 0.125);
}

.alert-success hr,
.alert-info hr,
.alert-warning hr,
.alert-danger hr {
  border-top-color: rgba(0, 0, 0, 0.125);
}

.alert-success {
  color: #43b968;
  background-color: #d9f1e1;
}

.alert-success .alert-link {
  color: #43b968;
}

.alert-info {
  color: #20b9ae;
  background-color: #c5f1ed;
}

.alert-info .alert-link {
  color: #20b9ae;
}

.alert-warning {
  color: #f59345;
  background-color: #fde9da;
}

.alert-warning .alert-link {
  color: #f59345;
}

.alert-danger {
  color: #f44236;
  background-color: #fdd9d7;
}

.alert-danger .alert-link {
  color: #f44236;
}

.alert-success-outline {
  color: #43b968;
  background-color: transparent;
  border-color: #43b968;
}

.alert-success-outline hr {
  border-top-color: #43b968;
}

.alert-success-outline .alert-link {
  color: #43b968;
}

.alert-info-outline {
  color: #20b9ae;
  background-color: transparent;
  border-color: #20b9ae;
}

.alert-info-outline hr {
  border-top-color: #20b9ae;
}

.alert-info-outline .alert-link {
  color: #20b9ae;
}

.alert-warning-outline {
  color: #f59345;
  background-color: transparent;
  border-color: #f59345;
}

.alert-warning-outline hr {
  border-top-color: #f59345;
}

.alert-warning-outline .alert-link {
  color: #f59345;
}

.alert-danger-outline {
  color: #f44236;
  background-color: transparent;
  border-color: #f44236;
}

.alert-danger-outline hr {
  border-top-color: #f44236;
}

.alert-danger-outline .alert-link {
  color: #f44236;
}

.alert-success-fill hr,
.alert-info-fill hr,
.alert-warning-fill hr,
.alert-danger-fill hr {
  border-top-color: rgba(255, 255, 255, 0.3);
}

.alert-success-fill {
  color: #fff;
  background-color: #43b968;
  border-color: #43b968;
}

.alert-success-fill .alert-link {
  color: #fff;
}

.alert-info-fill {
  color: #fff;
  background-color: #20b9ae;
  border-color: #20b9ae;
}

.alert-info-fill .alert-link {
  color: #fff;
}

.alert-warning-fill {
  color: #fff;
  background-color: #f59345;
  border-color: #f59345;
}

.alert-warning-fill .alert-link {
  color: #fff;
}

.alert-danger-fill {
  color: #fff;
  background-color: #f44236;
  border-color: #f44236;
}

.alert-danger-fill .alert-link {
  color: #fff;
}

/* Buttons */
.btn {
  /*border-radius: 0;*/
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.btn-lg,
.btn-group-lg > .btn {
  border-radius: 0;
}

.btn-sm,
.btn-group-sm > .btn {
  border-radius: 0;
}

.btn-rounded {
  border-radius: 25rem;
}

.btn.label-left,
.btn.label-right {
  position: relative;
}

.btn.label-left {
  padding-left: 49px;
}

.btn.label-right {
  padding-right: 49px;
}

.btn-sm.label-left {
  padding-left: 32px;
}

.btn-sm.label-right {
  padding-right: 32px;
}

.btn-lg.label-left {
  padding-left: 62px;
}

.btn-lg.label-right {
  padding-right: 62px;
}

.btn-label {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0.5rem 0;
  width: 33px;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
}

.btn-sm .btn-label {
  padding: 0.25rem 0;
  width: 24px;
}

.btn-lg .btn-label {
  padding: 0.75rem 0;
  width: 44px;
}

.btn-outline-primary .btn-label,
.btn-outline-secondary .btn-label,
.btn-outline-info .btn-label,
.btn-outline-success .btn-label,
.btn-outline-warning .btn-label,
.btn-outline-danger .btn-label,
.btn-outline-purple .btn-label,
.btn-outline-black .btn-label,
.btn-outline-white .btn-label {
  background-color: transparent;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: inherit;
}

.btn.label-left .btn-label {
  left: 0;
}

.btn.label-right .btn-label {
  right: 0;
}

.btn-rounded.label-left .btn-label {
  border-radius: 25rem 0 0 25rem;
}

.btn-rounded.label-right .btn-label {
  border-radius: 0 25rem 25rem 0;
}

.btn-circle {
  border-radius: 50%;
  padding: 0;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

.btn-xs.btn-circle {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}

.btn-sm.btn-circle {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
}

.btn-lg.btn-circle {
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
}

.btn-square {
  position: relative;
  padding: 0;
  width: 5rem;
  height: 5rem;
}

.btn-square i {
  display: block;
  margin-bottom: 0.25rem;
}

.btn-link:focus,
.btn-link:hover {
  text-decoration: none;
}

.btn-primary {
  background-color: #3e70c9;
  border-color: #3e70c9;
}

.btn-primary:hover {
  background-color: #467ee1;
  border-color: #467ee1;
}

.btn-primary:focus,
.btn-primary.focus {
  background-color: #467ee1;
  border-color: #467ee1;
}

.btn-primary:active,
.btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  background-color: #467ee1;
  border-color: #467ee1;
}

.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary:active.focus,
.btn-primary.active:hover,
.btn-primary.active:focus,
.btn-primary.active.focus,
.open > .btn-primary.dropdown-toggle:hover,
.open > .btn-primary.dropdown-toggle:focus,
.open > .btn-primary.dropdown-toggle.focus {
  background-color: #3662b1;
  border-color: #3662b1;
}

.btn-primary.disabled:focus,
.btn-primary.disabled.focus,
.btn-primary:disabled:focus,
.btn-primary:disabled.focus {
  background-color: #467ee1;
  border-color: #467ee1;
}

.btn-primary.disabled:hover,
.btn-primary:disabled:hover {
  background-color: #467ee1;
  border-color: #467ee1;
}

.btn-secondary {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-secondary:hover {
  color: #333;
  background-color: #eee;
  border-color: #aaa;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #333;
  background-color: #eee;
  border-color: #aaa;
}

.btn-secondary:active,
.btn-secondary.active,
.open > .btn-secondary.dropdown-toggle {
  color: #333;
  background-color: #eee;
  border-color: #bbb;
}

.btn-secondary:active:hover,
.btn-secondary:active:focus,
.btn-secondary:active.focus,
.btn-secondary.active:hover,
.btn-secondary.active:focus,
.btn-secondary.active.focus,
.open > .btn-secondary.dropdown-toggle:hover,
.open > .btn-secondary.dropdown-toggle:focus,
.open > .btn-secondary.dropdown-toggle.focus {
  color: #333;
  background-color: #ddd;
  border-color: #888;
}

.btn-secondary.disabled:focus,
.btn-secondary.disabled.focus,
.btn-secondary:disabled:focus,
.btn-secondary:disabled.focus {
  background-color: #fff;
  border-color: #bbb;
}

.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover {
  background-color: #fff;
  border-color: #bbb;
}

.btn-success {
  background-color: #43b968;
  border-color: #43b968;
}

.btn-success:hover {
  background-color: #4bcb73;
  border-color: #4bcb73;
}

.btn-success:focus,
.btn-success.focus {
  background-color: #4bcb73;
  border-color: #4bcb73;
}

.btn-success:active,
.btn-success.active,
.open > .btn-success.dropdown-toggle {
  background-color: #4bcb73;
  border-color: #4bcb73;
}

.btn-success:active:hover,
.btn-success:active:focus,
.btn-success:active.focus,
.btn-success.active:hover,
.btn-success.active:focus,
.btn-success.active.focus,
.open > .btn-success.dropdown-toggle:hover,
.open > .btn-success.dropdown-toggle:focus,
.open > .btn-success.dropdown-toggle.focus {
  background-color: #3aa25a;
  border-color: #3aa25a;
}

.btn-success.disabled:focus,
.btn-success.disabled.focus,
.btn-success:disabled:focus,
.btn-success:disabled.focus {
  background-color: #4bcb73;
  border-color: #4bcb73;
}

.btn-success.disabled:hover,
.btn-success:disabled:hover {
  background-color: #4bcb73;
  border-color: #4bcb73;
}

.btn-info {
  background-color: #20b9ae;
  border-color: #20b9ae;
}

.btn-info:hover {
  background-color: #33cbc0;
  border-color: #33cbc0;
}

.btn-info:focus,
.btn-info.focus {
  background-color: #33cbc0;
  border-color: #33cbc0;
}

.btn-info:active,
.btn-info.active,
.open > .btn-info.dropdown-toggle {
  background-color: #33cbc0;
  border-color: #33cbc0;
}

.btn-info:active:hover,
.btn-info:active:focus,
.btn-info:active.focus,
.btn-info.active:hover,
.btn-info.active:focus,
.btn-info.active.focus,
.open > .btn-info.dropdown-toggle:hover,
.open > .btn-info.dropdown-toggle:focus,
.open > .btn-info.dropdown-toggle.focus {
  background-color: #11a59a;
  border-color: #11a59a;
}

.btn-info.disabled:focus,
.btn-info.disabled.focus,
.btn-info:disabled:focus,
.btn-info:disabled.focus {
  background-color: #33cbc0;
  border-color: #33cbc0;
}

.btn-info.disabled:hover,
.btn-info:disabled:hover {
  background-color: #33cbc0;
  border-color: #33cbc0;
}

.btn-warning {
  background-color: #f59345;
  border-color: #f59345;
}

.btn-warning:hover {
  background-color: #fea45c;
  border-color: #fea45c;
}

.btn-warning:focus,
.btn-warning.focus {
  background-color: #fea45c;
  border-color: #fea45c;
}

.btn-warning:active,
.btn-warning.active,
.open > .btn-warning.dropdown-toggle {
  background-color: #fea45c;
  border-color: #fea45c;
}

.btn-warning:active:hover,
.btn-warning:active:focus,
.btn-warning:active.focus,
.btn-warning.active:hover,
.btn-warning.active:focus,
.btn-warning.active.focus,
.open > .btn-warning.dropdown-toggle:hover,
.open > .btn-warning.dropdown-toggle:focus,
.open > .btn-warning.dropdown-toggle.focus {
  background-color: #de853e;
  border-color: #de853e;
}

.btn-warning.disabled:focus,
.btn-warning.disabled.focus,
.btn-warning:disabled:focus,
.btn-warning:disabled.focus {
  background-color: #fea45c;
  border-color: #fea45c;
}

.btn-warning.disabled:hover,
.btn-warning:disabled:hover {
  background-color: #fea45c;
  border-color: #fea45c;
}

.btn-danger {
  background-color: #f44236;
  border-color: #f44236;
}

.btn-danger:hover {
  background-color: #ff574c;
  border-color: #ff574c;
}

.btn-danger:focus,
.btn-danger.focus {
  background-color: #ff574c;
  border-color: #ff574c;
}

.btn-danger:active,
.btn-danger.active,
.open > .btn-danger.dropdown-toggle {
  background-color: #ff574c;
  border-color: #ff574c;
}

.btn-danger:active:hover,
.btn-danger:active:focus,
.btn-danger:active.focus,
.btn-danger.active:hover,
.btn-danger.active:focus,
.btn-danger.active.focus,
.open > .btn-danger.dropdown-toggle:hover,
.open > .btn-danger.dropdown-toggle:focus,
.open > .btn-danger.dropdown-toggle.focus {
  background-color: #de3c31;
  border-color: #de3c31;
}

.btn-danger.disabled:focus,
.btn-danger.disabled.focus,
.btn-danger:disabled:focus,
.btn-danger:disabled.focus {
  background-color: #ff574c;
  border-color: #ff574c;
}

.btn-danger.disabled:hover,
.btn-danger:disabled:hover {
  background-color: #ff574c;
  border-color: #ff574c;
}

.btn-purple {
  color: #fff;
  background-color: #a567e2;
  border-color: #a567e2;
}

.btn-purple:hover {
  color: #fff;
  background-color: #b773f9;
  border-color: #b773f9;
}

.btn-purple:focus,
.btn-purple.focus {
  color: #fff;
  background-color: #b773f9;
  border-color: #b773f9;
}

.btn-purple:active,
.btn-purple.active,
.open > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #b773f9;
  border-color: #b773f9;
}

.btn-purple:active:hover,
.btn-purple:active:focus,
.btn-purple:active.focus,
.btn-purple.active:hover,
.btn-purple.active:focus,
.btn-purple.active.focus,
.open > .btn-purple.dropdown-toggle:hover,
.open > .btn-purple.dropdown-toggle:focus,
.open > .btn-purple.dropdown-toggle.focus {
  color: #fff;
  background-color: #915bc7;
  border-color: #915bc7;
}

.btn-purple.disabled:focus,
.btn-purple.disabled.focus,
.btn-purple:disabled:focus,
.btn-purple:disabled.focus {
  background-color: #b773f9;
  border-color: #b773f9;
}

.btn-purple.disabled:hover,
.btn-purple:disabled:hover {
  background-color: #b773f9;
  border-color: #b773f9;
}

.btn-black {
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.btn-black:hover {
  color: #fff;
  background-color: #444;
  border-color: #444;
}

.btn-black:focus,
.btn-black.focus {
  color: #fff;
  background-color: #444;
  border-color: #444;
}

.btn-black:active,
.btn-black.active,
.open > .btn-black.dropdown-toggle {
  color: #fff;
  background-color: #444;
  border-color: #444;
}

.btn-black:active:hover,
.btn-black:active:focus,
.btn-black:active.focus,
.btn-black.active:hover,
.btn-black.active:focus,
.btn-black.active.focus,
.open > .btn-black.dropdown-toggle:hover,
.open > .btn-black.dropdown-toggle:focus,
.open > .btn-black.dropdown-toggle.focus {
  color: #fff;
  background-color: #222;
  border-color: #222;
}

.btn-black.disabled:focus,
.btn-black.disabled.focus,
.btn-black:disabled:focus,
.btn-black:disabled.focus {
  background-color: #444;
  border-color: #444;
}

.btn-black.disabled:hover,
.btn-black:disabled:hover {
  background-color: #444;
  border-color: #444;
}

.btn-white {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:hover {
  color: #333;
  background-color: #eee;
  border-color: #eee;
}

.btn-white:focus,
.btn-white.focus {
  color: #333;
  background-color: #eee;
  border-color: #eee;
}

.btn-white:active,
.btn-white.active,
.open > .btn-white.dropdown-toggle {
  color: #333;
  background-color: #eee;
  border-color: #eee;
}

.btn-white:active:hover,
.btn-white:active:focus,
.btn-white:active.focus,
.btn-white.active:hover,
.btn-white.active:focus,
.btn-white.active.focus,
.open > .btn-white.dropdown-toggle:hover,
.open > .btn-white.dropdown-toggle:focus,
.open > .btn-white.dropdown-toggle.focus {
  color: #333;
  background-color: #eee;
  border-color: #eee;
}

.btn-white.disabled:focus,
.btn-white.disabled.focus,
.btn-white:disabled:focus,
.btn-white:disabled.focus {
  background-color: #eee;
  border-color: #eee;
}

.btn-white.disabled:hover,
.btn-white:disabled:hover {
  background-color: #eee;
  border-color: #eee;
}

.btn-outline-primary {
  color: #3e70c9;
  border-color: #3e70c9;
}

.btn-outline-primary:hover {
  background-color: #3e70c9;
  border-color: #3e70c9;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  background-color: #3e70c9;
  border-color: #3e70c9;
}

.btn-outline-primary:active,
.btn-outline-primary.active,
.open > .btn-outline-primary.dropdown-toggle {
  background-color: #3e70c9;
  border-color: #3e70c9;
}

.btn-outline-primary:active:hover,
.btn-outline-primary:active:focus,
.btn-outline-primary:active.focus,
.btn-outline-primary.active:hover,
.btn-outline-primary.active:focus,
.btn-outline-primary.active.focus,
.open > .btn-outline-primary.dropdown-toggle:hover,
.open > .btn-outline-primary.dropdown-toggle:focus,
.open > .btn-outline-primary.dropdown-toggle.focus {
  background-color: #3e70c9;
  border-color: #3e70c9;
}

.btn-outline-primary.disabled:focus,
.btn-outline-primary.disabled.focus,
.btn-outline-primary:disabled:focus,
.btn-outline-primary:disabled.focus {
  border-color: #3e70c9;
}

.btn-outline-primary.disabled:hover,
.btn-outline-primary:disabled:hover {
  border-color: #3e70c9;
}

.btn-outline-secondary {
  color: #ccc;
  border-color: #ccc;
}

.btn-outline-secondary:hover {
  background-color: #ccc;
  border-color: #ccc;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  background-color: #ccc;
  border-color: #ccc;
}

.btn-outline-secondary:active,
.btn-outline-secondary.active,
.open > .btn-outline-secondary.dropdown-toggle {
  background-color: #ccc;
  border-color: #ccc;
}

.btn-outline-secondary:active:hover,
.btn-outline-secondary:active:focus,
.btn-outline-secondary:active.focus,
.btn-outline-secondary.active:hover,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.active.focus,
.open > .btn-outline-secondary.dropdown-toggle:hover,
.open > .btn-outline-secondary.dropdown-toggle:focus,
.open > .btn-outline-secondary.dropdown-toggle.focus {
  background-color: #ccc;
  border-color: #ccc;
}

.btn-outline-secondary.disabled:focus,
.btn-outline-secondary.disabled.focus,
.btn-outline-secondary:disabled:focus,
.btn-outline-secondary:disabled.focus {
  border-color: #ccc;
}

.btn-outline-secondary.disabled:hover,
.btn-outline-secondary:disabled:hover {
  border-color: #ccc;
}

.btn-outline-info {
  color: #20b9ae;
  border-color: #20b9ae;
}

.btn-outline-info:hover {
  background-color: #20b9ae;
  border-color: #20b9ae;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  background-color: #20b9ae;
  border-color: #20b9ae;
}

.btn-outline-info:active,
.btn-outline-info.active,
.open > .btn-outline-info.dropdown-toggle {
  background-color: #20b9ae;
  border-color: #20b9ae;
}

.btn-outline-info:active:hover,
.btn-outline-info:active:focus,
.btn-outline-info:active.focus,
.btn-outline-info.active:hover,
.btn-outline-info.active:focus,
.btn-outline-info.active.focus,
.open > .btn-outline-info.dropdown-toggle:hover,
.open > .btn-outline-info.dropdown-toggle:focus,
.open > .btn-outline-info.dropdown-toggle.focus {
  background-color: #20b9ae;
  border-color: #20b9ae;
}

.btn-outline-info.disabled:focus,
.btn-outline-info.disabled.focus,
.btn-outline-info:disabled:focus,
.btn-outline-info:disabled.focus {
  border-color: #20b9ae;
}

.btn-outline-info.disabled:hover,
.btn-outline-info:disabled:hover {
  border-color: #20b9ae;
}

.btn-outline-success {
  color: #43b968;
  border-color: #43b968;
}

.btn-outline-success:hover {
  background-color: #43b968;
  border-color: #43b968;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  background-color: #43b968;
  border-color: #43b968;
}

.btn-outline-success:active,
.btn-outline-success.active,
.open > .btn-outline-success.dropdown-toggle {
  background-color: #43b968;
  border-color: #43b968;
}

.btn-outline-success:active:hover,
.btn-outline-success:active:focus,
.btn-outline-success:active.focus,
.btn-outline-success.active:hover,
.btn-outline-success.active:focus,
.btn-outline-success.active.focus,
.open > .btn-outline-success.dropdown-toggle:hover,
.open > .btn-outline-success.dropdown-toggle:focus,
.open > .btn-outline-success.dropdown-toggle.focus {
  background-color: #43b968;
  border-color: #43b968;
}

.btn-outline-success.disabled:focus,
.btn-outline-success.disabled.focus,
.btn-outline-success:disabled:focus,
.btn-outline-success:disabled.focus {
  border-color: #43b968;
}

.btn-outline-success.disabled:hover,
.btn-outline-success:disabled:hover {
  border-color: #43b968;
}

.btn-outline-warning {
  color: #f59345;
  border-color: #f59345;
}

.btn-outline-warning:hover {
  background-color: #f59345;
  border-color: #f59345;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  background-color: #f59345;
  border-color: #f59345;
}

.btn-outline-warning:active,
.btn-outline-warning.active,
.open > .btn-outline-warning.dropdown-toggle {
  background-color: #f59345;
  border-color: #f59345;
}

.btn-outline-warning:active:hover,
.btn-outline-warning:active:focus,
.btn-outline-warning:active.focus,
.btn-outline-warning.active:hover,
.btn-outline-warning.active:focus,
.btn-outline-warning.active.focus,
.open > .btn-outline-warning.dropdown-toggle:hover,
.open > .btn-outline-warning.dropdown-toggle:focus,
.open > .btn-outline-warning.dropdown-toggle.focus {
  background-color: #f59345;
  border-color: #f59345;
}

.btn-outline-warning.disabled:focus,
.btn-outline-warning.disabled.focus,
.btn-outline-warning:disabled:focus,
.btn-outline-warning:disabled.focus {
  border-color: #f59345;
}

.btn-outline-warning.disabled:hover,
.btn-outline-warning:disabled:hover {
  border-color: #f59345;
}

.btn-outline-danger {
  color: #f44236;
  border-color: #f44236;
}

.btn-outline-danger:hover {
  background-color: #f44236;
  border-color: #f44236;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  background-color: #f44236;
  border-color: #f44236;
}

.btn-outline-danger:active,
.btn-outline-danger.active,
.open > .btn-outline-danger.dropdown-toggle {
  background-color: #f44236;
  border-color: #f44236;
}

.btn-outline-danger:active:hover,
.btn-outline-danger:active:focus,
.btn-outline-danger:active.focus,
.btn-outline-danger.active:hover,
.btn-outline-danger.active:focus,
.btn-outline-danger.active.focus,
.open > .btn-outline-danger.dropdown-toggle:hover,
.open > .btn-outline-danger.dropdown-toggle:focus,
.open > .btn-outline-danger.dropdown-toggle.focus {
  background-color: #f44236;
  border-color: #f44236;
}

.btn-outline-danger.disabled:focus,
.btn-outline-danger.disabled.focus,
.btn-outline-danger:disabled:focus,
.btn-outline-danger:disabled.focus {
  border-color: #f44236;
}

.btn-outline-danger.disabled:hover,
.btn-outline-danger:disabled:hover {
  border-color: #f44236;
}

.btn-outline-purple {
  color: #a567e2;
  background-image: none;
  background-color: transparent;
  border-color: #a567e2;
}

.btn-outline-purple:hover {
  color: #fff;
  background-color: #a567e2;
  border-color: #a567e2;
}

.btn-outline-purple:focus,
.btn-outline-purple.focus {
  color: #fff;
  background-color: #a567e2;
  border-color: #a567e2;
}

.btn-outline-purple:active,
.btn-outline-purple.active,
.open > .btn-outline-purple.dropdown-toggle {
  color: #fff;
  background-color: #a567e2;
  border-color: #a567e2;
}

.btn-outline-purple:active:hover,
.btn-outline-purple:active:focus,
.btn-outline-purple:active.focus,
.btn-outline-purple.active:hover,
.btn-outline-purple.active:focus,
.btn-outline-purple.active.focus,
.open > .btn-outline-purple.dropdown-toggle:hover,
.open > .btn-outline-purple.dropdown-toggle:focus,
.open > .btn-outline-purple.dropdown-toggle.focus {
  color: #fff;
  background-color: #a567e2;
  border-color: #a567e2;
}

.btn-outline-purple.disabled:focus,
.btn-outline-purple.disabled.focus,
.btn-outline-purple:disabled:focus,
.btn-outline-purple:disabled.focus {
  border-color: #a567e2;
}

.btn-outline-purple.disabled:hover,
.btn-outline-purple:disabled:hover {
  border-color: #a567e2;
}

.btn-outline-black {
  color: #333;
  background-image: none;
  background-color: transparent;
  border-color: #333;
}

.btn-outline-black:hover {
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.btn-outline-black:focus,
.btn-outline-black.focus {
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.btn-outline-black:active,
.btn-outline-black.active,
.open > .btn-outline-black.dropdown-toggle {
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.btn-outline-black:active:hover,
.btn-outline-black:active:focus,
.btn-outline-black:active.focus,
.btn-outline-black.active:hover,
.btn-outline-black.active:focus,
.btn-outline-black.active.focus,
.open > .btn-outline-black.dropdown-toggle:hover,
.open > .btn-outline-black.dropdown-toggle:focus,
.open > .btn-outline-black.dropdown-toggle.focus {
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.btn-outline-black.disabled:focus,
.btn-outline-black.disabled.focus,
.btn-outline-black:disabled:focus,
.btn-outline-black:disabled.focus {
  border-color: #333;
}

.btn-outline-black.disabled:hover,
.btn-outline-black:disabled:hover {
  border-color: #333;
}

.btn-outline-white {
  color: #fff;
  background-image: none;
  background-color: transparent;
  border-color: #fff;
}

.btn-outline-white:hover {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:focus,
.btn-outline-white.focus {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:active,
.btn-outline-white.active,
.open > .btn-outline-white.dropdown-toggle {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:active:hover,
.btn-outline-white:active:focus,
.btn-outline-white:active.focus,
.btn-outline-white.active:hover,
.btn-outline-white.active:focus,
.btn-outline-white.active.focus,
.open > .btn-outline-white.dropdown-toggle:hover,
.open > .btn-outline-white.dropdown-toggle:focus,
.open > .btn-outline-white.dropdown-toggle.focus {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white.disabled:focus,
.btn-outline-white.disabled.focus,
.btn-outline-white:disabled:focus,
.btn-outline-white:disabled.focus {
  border-color: #fff;
}

.btn-outline-white.disabled:hover,
.btn-outline-white:disabled:hover {
  border-color: #fff;
}

/* Card */
.card {
  border-radius: 0;
}

.card-img {
  border-radius: 0;
}

.card-primary {
  background-color: #3e70c9;
  border-color: #3e70c9;
}

.card-success {
  background-color: #43b968;
  border-color: #43b968;
}

.card-info {
  background-color: #20b9ae;
  border-color: #20b9ae;
}

.card-warning {
  background-color: #f59345;
  border-color: #f59345;
}

.card-danger {
  background-color: #f44236;
  border-color: #f44236;
}

.card-purple {
  background-color: #a567e2;
  border-color: #a567e2;
}

.card-black {
  background-color: #333;
  border-color: #333;
}

.card-outline-primary {
  border-color: #3e70c9;
}

.card-outline-secondary {
  border-color: #ccc;
}

.card-outline-info {
  border-color: #20b9ae;
}

.card-outline-success {
  border-color: #43b968;
}

.card-outline-warning {
  border-color: #f59345;
}

.card-outline-danger {
  border-color: #f44236;
}

.card-outline-purple {
  background-color: transparent;
  border-color: #a567e2;
}

.card-outline-black {
  background-color: transparent;
  border-color: #333;
}

/* Nav */
.nav-1 {
  text-align: center;
}

.nav-1 .nav-link {
  display: inline-block;
  padding: 1rem;
  /*border-right: 1px solid rgba(0, 0, 0, 0.125);*/
}

.pad-20-30 {
  padding: 9px 0px !important;
  width: 90px;
  margin: 0 auto;
}

.nav-1 .nav-link span {
  display: inline-block;
  font-size: 1.5rem;
}

.padd-45 {
  padding: 0 45px !important;
}

.float-left {
  float: left !important;
}

.jconfirm-buttons {
  float: none !important;
}

.nav-1 .nav-link:focus,
.nav-1 .nav-link:hover {
  color: #3e70c9;
  background-color: #f5f5f5;
}

.nav-1 .active,
.nav-1 .active:focus,
.nav-1 .active:hover {
  color: #fff;
  background-color: #3e70c9;
}

.nav-2 {
  text-align: center;
}

.nav-2 .nav-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.nav-2 .nav-link {
  display: block;
  padding: 1rem 0;
  color: #777;
}

.email-chat {
  margin-left: 15px;
}

.nav-2 .nav-link span {
  display: inline-block;
  font-size: 1.5rem;
}

.nav-2 .nav-link:focus,
.nav-2 .nav-link:hover {
  color: #3e70c9;
  background-color: #f5f5f5;
}

.nav-2 .active .nav-link,
.nav-2 .active .nav-link:focus,
.nav-2 .active .nav-link:hover {
  color: #fff;
  background-color: #3e70c9;
}

.nav-3 {
  text-align: center;
}

.nav-3 .nav-link {
  display: block;
  padding: 1rem 0;
}

.nav-3 .nav-link .avatar {
  display: inline-block;
}

.nav-3 .nav-link i {
  font-size: 1.25rem;
}

.nav-3 .nav-link:focus,
.nav-3 .nav-link:hover {
  color: #3e70c9;
  background-color: #f5f5f5;
}

.nav-3 .active .nav-link,
.nav-3 .active .nav-link:focus,
.nav-3 .active .nav-link:hover {
  color: #fff;
  background-color: #3e70c9;
}

.nav-4 .nav-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.nav-4 .nav-link {
  display: block;
  padding: 1rem 1.5rem;
  color: #777;
  border-left: 3px solid transparent;
}

.nav-4 .nav-link i {
  margin-right: 0.75rem;
}

.nav-4 .nav-link:focus,
.nav-4 .nav-link:hover {
  background-color: #f5f5f5;
}

.nav-4 .active .nav-link,
.nav-4 .active .nav-link:focus,
.nav-4 .active .nav-link:hover {
  color: #3e70c9;
  border-color: #3e70c9;
}

.nav-5 .nav-title {
  padding: 0.5rem 1.5rem;
  background-color: #eee;
  font-size: 90%;
  text-transform: uppercase;
}

.nav-5 .nav-link {
  display: block;
  padding: 0.5rem 1.5rem;
  color: #777;
}

.nav-5 .nav-link i {
  margin-right: 0.75rem;
}

.nav-5 .nav-link:focus,
.nav-5 .nav-link:hover {
  color: #3e70c9;
}

.nav-5 .active .nav-link,
.nav-5 .active .nav-link:focus,
.nav-5 .active .nav-link:hover {
  color: #3e70c9;
}

.nav-6 .nav-item {
  display: block;
  position: relative;
  text-align: center;
}

.nav-6 .gradient {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.8;
}

.nav-6 .nav-link {
  display: block;
  position: relative;
  z-index: 10;
  padding: 3rem 1.5rem;
  color: #fff;
  text-transform: uppercase;
}

.nav-6 .nav-link span {
  font-size: 90%;
  text-transform: none;
}

.text-transform-none {
  text-transform: none !important;
}

.nav-6 .nav-link:focus,
.nav-6 .nav-link:hover {
  color: rgba(255, 255, 255, 0.9);
}

.nav-6 .active .nav-link,
.nav-6 .active .nav-link:focus,
.nav-6 .active .nav-link:hover {
  color: #fff;
}

.nav-7 .nav-item {
  border-left: 3px solid transparent;
}

.nav-7 .nav-link {
  display: block;
  padding: 0.75rem 1.5rem;
  color: #777;
}

.nav-7 .nav-link i {
  margin-right: 0.75rem;
  color: #3e70c9;
}

.nav-7 .nav-item ul {
  display: none;
  margin: 0;
  padding: 0 0 0.75rem 27px;
  list-style: none;
}

.nav-7 .nav-item.opened {
  display: block;
  background-color: #f5f5f5;
  border-color: #3e70c9;
}

.nav-7 .nav-item.opened ul {
  display: block;
}

.nav-7 .nav-link:focus,
.nav-7 .nav-link:hover {
  color: #3e70c9;
}

.nav-7 .active .nav-link,
.nav-7 .active .nav-link:focus,
.nav-7 .active .nav-link:hover {
  color: #3e70c9;
}

.nav-8 .nav-link {
  display: block;
  padding: 0.5rem 0;
  color: #777;
}

.nav-8 .nav-link i {
  margin-right: 0.75rem;
}

.nav-8 .nav-link:focus,
.nav-8 .nav-link:hover {
  color: #3e70c9;
}

.nav-8 .active .nav-link,
.nav-8 .active .nav-link:focus,
.nav-8 .active .nav-link:hover {
  color: #3e70c9;
  background-color: transparent;
}

/* Nav tabs and pills */
.v-tabs {
  border: none !important;
  border-right: 1px solid #ddd !important;
}

.v-tabs .nav-item {
  display: block;
  float: none;
}

.v-tabs .nav-item + .nav-item {
  margin-left: 0;
  margin-top: 0.5rem;
}

.v-tabs .nav-link {
  border-right: none;
}

.v-tabs .nav-link:focus,
.v-tabs .nav-link:hover {
  border-color: #eceeef;
}

.v-tabs .nav-link.active,
.v-tabs .nav-link.active:focus,
.v-tabs .nav-link.active:hover {
  border-color: #ddd;
}

.nav-tabs .nav-link {
  padding: 0.5em 1.25em;
  color: #333;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.nav-tabs-2 {
  border-bottom-color: #eee;
}

.nav-tabs-2 .nav-item + .nav-item {
  margin-left: 0;
}

.nav-tabs-2 .nav-link {
  border: none;
  border-bottom: 1px solid transparent;
}

.nav-tabs-2 .nav-link.active,
.nav-tabs-2 .nav-link.active:focus,
.nav-tabs-2 .nav-link.active:hover {
  color: #333;
  border-color: #3e70c9;
}

.nav-tabs-2 .nav-item.open .nav-link,
.nav-tabs-2 .nav-item.open .nav-link:focus,
.nav-tabs-2 .nav-item.open .nav-link:hover {
  border-color: #eee;
}

.nav-tabs-3 .nav-link {
  border: none;
}

.nav-tabs-3 .nav-link:focus,
.nav-tabs-3 .nav-link:hover {
  background-color: #eee;
}

.nav-tabs-3 .nav-link.active,
.nav-tabs-3 .nav-link.active:focus,
.nav-tabs-3 .nav-link.active:hover {
  color: #fff;
  background-color: #3e70c9;
}

.nav-tabs-3 .nav-item.open .nav-link,
.nav-tabs-3 .nav-item.open .nav-link:focus,
.nav-tabs-3 .nav-item.open .nav-link:hover {
  background-color: #eee;
}

.nav-pills .nav-link {
  border-radius: 0;
}

.nav-pills .nav-link.active,
.nav-pills .nav-link.active:focus,
.nav-pills .nav-link.active:hover,
.nav-pills .nav-item.open .nav-link,
.nav-pills .nav-item.open .nav-link:focus,
.nav-pills .nav-item.open .nav-link:hover {
  background-color: #3e70c9;
}

.nav-pills-2 .nav-link {
  border: 1px solid transparent;
}

.nav-pills-2 .nav-link.active,
.nav-pills-2 .nav-link.active:focus,
.nav-pills-2 .nav-link.active:hover {
  color: #3e70c9;
  background-color: transparent;
  border-color: #3e70c9;
}

.nav-pills-2 .nav-item.open .nav-link,
.nav-pills-2 .nav-item.open .nav-link:focus,
.nav-pills-2 .nav-item.open .nav-link:hover {
  color: #3e70c9;
  background-color: transparent;
}

.nav-pills-rounded .nav-link {
  border-radius: 25rem;
}

/* Progress */
.progress-sm {
  height: 0.5rem;
}

.progress-lg {
  height: 1.5rem;
}

.progress[value] {
  border-radius: 0;
}

.progress[value]::-moz-progress-bar {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.progress[value]::-webkit-progress-value {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.progress[value="100"]::-moz-progress-bar {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.progress[value="100"]::-webkit-progress-value {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.progress[value]::-webkit-progress-bar {
  border-radius: 0;
}

base::-moz-progress-bar,
.progress[value] {
  border-radius: 0;
}

@media screen and (min-width: 0\0) {
  .progress {
    border-radius: 0;
  }

  .progress-bar {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .progress[width="100%"] {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.progress-rounded[value] {
  border-radius: 0.25rem;
}

.progress-rounded[value]::-moz-progress-bar {
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.progress-rounded[value]::-webkit-progress-value {
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.progress-rounded[value="100"]::-moz-progress-bar {
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.progress-rounded[value="100"]::-webkit-progress-value {
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.progress-rounded[value]::-webkit-progress-bar {
  border-radius: 0.25rem;
}

base::-moz-progress-bar,
.progress-rounded[value] {
  border-radius: 0.25rem;
}

@media screen and (min-width: 0\0) {
  .progress-rounded {
    border-radius: 0.25rem;
  }

  .progress-rounded-bar {
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
  }

  .progress-rounded[width="100%"] {
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
}

.progress-success[value]::-webkit-progress-value {
  background-color: #43b968;
}

.progress-success[value]::-moz-progress-bar {
  background-color: #43b968;
}

.progress-success[value]::-ms-fill {
  background-color: #43b968;
}

@media screen and (min-width: 0\0) {
  .progress-success .progress-bar {
    background-color: #43b968;
  }
}

.progress-info[value]::-webkit-progress-value {
  background-color: #20b9ae;
}

.progress-info[value]::-moz-progress-bar {
  background-color: #20b9ae;
}

.progress-info[value]::-ms-fill {
  background-color: #20b9ae;
}

@media screen and (min-width: 0\0) {
  .progress-info .progress-bar {
    background-color: #20b9ae;
  }
}

.progress-warning[value]::-webkit-progress-value {
  background-color: #f59345;
}

.progress-warning[value]::-moz-progress-bar {
  background-color: #f59345;
}

.progress-warning[value]::-ms-fill {
  background-color: #f59345;
}

@media screen and (min-width: 0\0) {
  .progress-warning .progress-bar {
    background-color: #f59345;
  }
}

.progress-danger[value]::-webkit-progress-value {
  background-color: #f44236;
}

.progress-danger[value]::-moz-progress-bar {
  background-color: #f44236;
}

.progress-danger[value]::-ms-fill {
  background-color: #f44236;
}

@media screen and (min-width: 0\0) {
  .progress-danger .progress-bar {
    background-color: #f44236;
  }
}

/* Tag */
.tag {
  padding: 0.2em 0.4em 0.3em;
  font-size: 80%;
  min-width: 20px;
  border-radius: 0;
}

.tag-pill {
  padding-right: 0.7em;
  padding-left: 0.7em;
  border-radius: 10rem;
}

.tag-default {
  background-color: #adb7be;
}

.tag-default[href]:focus,
.tag-default[href]:hover {
  background-color: #c6d1d8;
}

.tag-primary {
  background-color: #3e70c9;
}

.tag-primary[href]:focus,
.tag-primary[href]:hover {
  background-color: #467ee1;
}

.tag-success {
  background-color: #43b968;
}

.tag-success[href]:focus,
.tag-success[href]:hover {
  background-color: #4bcb73;
}

.tag-info {
  background-color: #20b9ae;
}

.tag-info[href]:focus,
.tag-info[href]:hover {
  background-color: #33cbc0;
}

.tag-warning {
  background-color: #f59345;
}

.tag-warning[href]:focus,
.tag-warning[href]:hover {
  background-color: #fea45c;
}

.tag-danger {
  background-color: #f44236;
}

.tag-danger[href]:focus,
.tag-danger[href]:hover {
  background-color: #ff574c;
}

.tag-purple {
  background-color: #a567e2;
}

.tag-purple[href]:focus,
.tag-purple[href]:hover {
  background-color: #a567e2;
}

.tag-black {
  background-color: #333;
}

.tag-black[href]:focus,
.tag-black[href]:hover {
  background-color: #333;
}

/* Tooltip */
.tooltip-inner {
  background-color: #333;
  border-radius: 0;
}

.tooltip.tooltip-top .tooltip-inner::before,
.tooltip.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #333;
}

.tooltip.tooltip-right .tooltip-inner::before,
.tooltip.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #333;
}

.tooltip.tooltip-bottom .tooltip-inner::before,
.tooltip.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #333;
}

.tooltip.tooltip-left .tooltip-inner::before,
.tooltip.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #333;
}

.tooltip-primary .tooltip-inner {
  background-color: #3e70c9;
}

.tooltip-primary.tooltip.tooltip-top .tooltip-inner::before,
.tooltip-primary.tooltip.bs-tether-element-attached-bottom
  .tooltip-inner::before {
  border-top-color: #3e70c9;
}

.tooltip-primary.tooltip.tooltip-right .tooltip-inner::before,
.tooltip-primary.tooltip.bs-tether-element-attached-left
  .tooltip-inner::before {
  border-right-color: #3e70c9;
}

.tooltip-primary.tooltip.tooltip-bottom .tooltip-inner::before,
.tooltip-primary.tooltip.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #3e70c9;
}

.tooltip-primary.tooltip.tooltip-left .tooltip-inner::before,
.tooltip-primary.tooltip.bs-tether-element-attached-right
  .tooltip-inner::before {
  border-left-color: #3e70c9;
}

.tooltip-success .tooltip-inner {
  background-color: #43b968;
}

.tooltip-success.tooltip.tooltip-top .tooltip-inner::before,
.tooltip-success.tooltip.bs-tether-element-attached-bottom
  .tooltip-inner::before {
  border-top-color: #43b968;
}

.tooltip-success.tooltip.tooltip-right .tooltip-inner::before,
.tooltip-success.tooltip.bs-tether-element-attached-left
  .tooltip-inner::before {
  border-right-color: #43b968;
}

.tooltip-success.tooltip.tooltip-bottom .tooltip-inner::before,
.tooltip-success.tooltip.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #43b968;
}

.tooltip-success.tooltip.tooltip-left .tooltip-inner::before,
.tooltip-success.tooltip.bs-tether-element-attached-right
  .tooltip-inner::before {
  border-left-color: #43b968;
}

.tooltip-info .tooltip-inner {
  background-color: #20b9ae;
}

.tooltip-info.tooltip.tooltip-top .tooltip-inner::before,
.tooltip-info.tooltip.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #20b9ae;
}

.tooltip-info.tooltip.tooltip-right .tooltip-inner::before,
.tooltip-info.tooltip.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #20b9ae;
}

.tooltip-info.tooltip.tooltip-bottom .tooltip-inner::before,
.tooltip-info.tooltip.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #20b9ae;
}

.tooltip-info.tooltip.tooltip-left .tooltip-inner::before,
.tooltip-info.tooltip.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #20b9ae;
}

.tooltip-warning .tooltip-inner {
  background-color: #f59345;
}

.tooltip-warning.tooltip.tooltip-top .tooltip-inner::before,
.tooltip-warning.tooltip.bs-tether-element-attached-bottom
  .tooltip-inner::before {
  border-top-color: #f59345;
}

.tooltip-warning.tooltip.tooltip-right .tooltip-inner::before,
.tooltip-warning.tooltip.bs-tether-element-attached-left
  .tooltip-inner::before {
  border-right-color: #f59345;
}

.tooltip-warning.tooltip.tooltip-bottom .tooltip-inner::before,
.tooltip-warning.tooltip.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #f59345;
}

.tooltip-warning.tooltip.tooltip-left .tooltip-inner::before,
.tooltip-warning.tooltip.bs-tether-element-attached-right
  .tooltip-inner::before {
  border-left-color: #f59345;
}

.tooltip-danger .tooltip-inner {
  background-color: #f44236;
}

.tooltip-danger.tooltip.tooltip-top .tooltip-inner::before,
.tooltip-danger.tooltip.bs-tether-element-attached-bottom
  .tooltip-inner::before {
  border-top-color: #f44236;
}

.tooltip-danger.tooltip.tooltip-right .tooltip-inner::before,
.tooltip-danger.tooltip.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #f44236;
}

.tooltip-danger.tooltip.tooltip-bottom .tooltip-inner::before,
.tooltip-danger.tooltip.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #f44236;
}

.tooltip-danger.tooltip.tooltip-left .tooltip-inner::before,
.tooltip-danger.tooltip.bs-tether-element-attached-right
  .tooltip-inner::before {
  border-left-color: #f44236;
}

.tooltip-purple .tooltip-inner {
  background-color: #a567e2;
}

.tooltip-purple.tooltip.tooltip-top .tooltip-inner::before,
.tooltip-purple.tooltip.bs-tether-element-attached-bottom
  .tooltip-inner::before {
  border-top-color: #a567e2;
}

.tooltip-purple.tooltip.tooltip-right .tooltip-inner::before,
.tooltip-purple.tooltip.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #a567e2;
}

.tooltip-purple.tooltip.tooltip-bottom .tooltip-inner::before,
.tooltip-purple.tooltip.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #a567e2;
}

.tooltip-purple.tooltip.tooltip-left .tooltip-inner::before,
.tooltip-purple.tooltip.bs-tether-element-attached-right
  .tooltip-inner::before {
  border-left-color: #a567e2;
}

/* Other */
abbr[title] {
  text-decoration: none;
}

.breadcrumb {
  border-radius: 0;
}

.breadcrumb.no-bg {
  background-color: transparent;
  padding: 0;
}

code {
  color: #f44236;
  background-color: #f9e5e4;
}

.close {
  text-shadow: none;
}

.custom-control-indicator {
  width: 16px;
  height: 16px;
  line-height: 16px;
}

.custom-control-input:checked ~ .custom-control-indicator {
  background-color: #3e70c9;
}

.custom-control-input:focus ~ .custom-control-indicator {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-control-input:active ~ .custom-control-indicator {
  background-color: #e5eff2;
}

.custom-checkbox .custom-control-indicator {
  border-radius: 0;
  -webkit-background-size: 70% 70%;
  background-size: 70% 70%;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.custom-checkbox .custom-control-description {
  margin-left: 0.5rem;
}

.custom-select {
  border-radius: 0;
}

.custom-select:focus {
  border-color: #3e70c9;
}

.dropdown-menu {
  border-radius: 0;
}

.dropdown-item.active,
.dropdown-item.active:focus,
.dropdown-item.active:hover {
  background-color: #3e70c9;
}

/*.form-control {*/
/*border-radius: 0;*/
/*}*/

.form-control:focus {
  border-color: #ccc;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600;
}

.input-group-addon {
  padding: 0.45rem 0.75rem;
  border-radius: 0;
}

.img-thumbnail {
  border-radius: 0;
}

.mark,
mark {
  background-color: #f9efb8;
}

.media-left {
  padding-right: 1rem;
}

.modal-backdrop {
  background-color: #333;
}

.modal-content {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

.pagination {
  border-radius: 0;
}

.page-link {
  color: #999;
}

.page-link:focus,
.page-link:hover {
  color: #333;
  background-color: #eceeef;
  border-color: #ddd;
}

.page-item:first-child .page-link {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.page-item:last-child .page-link {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.pagination-lg .page-item:first-child .page-link {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.pagination-lg .page-item:last-child .page-link {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.pagination-sm .page-item:first-child .page-link {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.pagination-sm .page-item:last-child .page-link {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.page-item.active .page-link,
.page-item.active .page-link:focus,
.page-item.active .page-link:hover {
  background-color: #3e70c9;
  border-color: #3e70c9;
}

.popover {
  font-size: 1rem;
  border-radius: 0;
}

select.form-control:not([size]):not([multiple]) {
  height: 35px;
}

.table-grey-head thead {
  background-color: #f5f5f5;
}

.text-muted {
  color: #999;
}

.form-control {
  color: #000 !important;
}

/* =================================================================
  Helper classes
================================================================= */

/* Background */
.bg-primary {
  color: #fff !important;
  background-color: #3e70c9 !important;
}

a.bg-primary:focus,
a.bg-primary:hover {
  background-color: #467ee1 !important;
}

.bg-secondary {
  color: #fff !important;
  background-color: #888 !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover {
  background-color: #999 !important;
}

.bg-info {
  color: #fff !important;
  background-color: #20b9ae !important;
}

a.bg-info:focus,
a.bg-info:hover {
  background-color: #5ad3e4 !important;
}

.bg-success {
  color: #fff !important;
  background-color: #43b968 !important;
}

a.bg-success:focus,
a.bg-success:hover {
  background-color: #4bcb73 !important;
}

.bg-warning {
  color: #fff !important;
  background-color: #f59345 !important;
}

a.bg-warning:focus,
a.bg-warning:hover {
  background-color: #fea45c !important;
}

.bg-danger {
  color: #fff !important;
  background-color: #f44236 !important;
}

a.bg-danger:focus,
a.bg-danger:hover {
  background-color: #ff574c !important;
}

.bg-purple {
  background-color: #602d91 !important;
}

a.bg-purple:focus,
a.bg-purple:hover {
  background-color: #b773f9 !important;
}

.bg-black {
  color: #fff !important;
  background-color: #333 !important;
}

a.bg-black:focus,
a.bg-black:hover {
  background-color: #444 !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:focus,
a.bg-white:hover {
  background-color: #fff !important;
}

.bg-facebook {
  color: #fff !important;
  background-color: #3b5998 !important;
}

.bg-twitter {
  color: #fff !important;
  background-color: #00aced !important;
}

.bg-linkedin {
  color: #fff !important;
  background-color: #007bb6 !important;
}

.bg-dribbble {
  color: #fff !important;
  background-color: #ea4c89 !important;
}

.bg-googleplus {
  color: #fff !important;
  background-color: #dd4b39 !important;
}

.bg-instagram {
  color: #fff !important;
  background-color: #517fa4 !important;
}

.bg-pinterest {
  color: #fff !important;
  background-color: #cb2027 !important;
}

.bg-dropbox {
  color: #fff !important;
  background-color: #007ee5 !important;
}

.bg-flickr {
  color: #fff !important;
  background-color: #ff0084 !important;
}

.bg-tumblr {
  color: #fff !important;
  background-color: #32506d !important;
}

.bg-skype {
  color: #fff !important;
  background-color: #00aff0 !important;
}

.bg-youtube {
  color: #fff !important;
  background-color: #bb0000 !important;
}

/* Box */
.box {
  position: relative;
  display: block;
  margin-bottom: 0.75rem;
  /*border: 1px solid rgba(0, 0, 0, 0.125);*/
}

.box-block {
  padding: 1.25rem;
}

.box-32 {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.box-48 {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.box-64 {
  width: 64px;
  height: 64px;
  line-height: 64px;
}

.box-96 {
  width: 96px;
  height: 96px;
  line-height: 96px;
}

.box-128 {
  width: 128px;
  height: 128px;
  line-height: 128px;
}

.box-160 {
  width: 160px;
  height: 160px;
  line-height: 160px;
}

/* Gradient */
.gradient-primary {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#3e70c9),
    to(#833ec9)
  );
  background-image: -webkit-linear-gradient(left, #3e70c9 0%, #833ec9 100%);
  background-image: -o-linear-gradient(left, #3e70c9 0%, #833ec9 100%);
  background-image: linear-gradient(to right, #3e70c9 0%, #833ec9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3e70c9', endColorstr='#833ec9', GradientType=1);
  background-repeat: repeat-x;
}

.gradient-info {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#20b9ae),
    to(#006fd6)
  );
  background-image: -webkit-linear-gradient(left, #20b9ae 0%, #006fd6 100%);
  background-image: -o-linear-gradient(left, #20b9ae 0%, #006fd6 100%);
  background-image: linear-gradient(to right, #20b9ae 0%, #006fd6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#20b9ae', endColorstr='#006fd6', GradientType=1);
  background-repeat: repeat-x;
}

.gradient-success {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#43b968),
    to(#43acb9)
  );
  background-image: -webkit-linear-gradient(left, #43b968 0%, #43acb9 100%);
  background-image: -o-linear-gradient(left, #43b968 0%, #43acb9 100%);
  background-image: linear-gradient(to right, #43b968 0%, #43acb9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#43b968', endColorstr='#43acb9', GradientType=1);
  background-repeat: repeat-x;
}

.gradient-warning {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f59345),
    to(#f5eb45)
  );
  background-image: -webkit-linear-gradient(left, #f59345 0%, #f5eb45 100%);
  background-image: -o-linear-gradient(left, #f59345 0%, #f5eb45 100%);
  background-image: linear-gradient(to right, #f59345 0%, #f5eb45 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f59345', endColorstr='#f5eb45', GradientType=1);
  background-repeat: repeat-x;
}

.gradient-danger {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f44236),
    to(#f4e936)
  );
  background-image: -webkit-linear-gradient(left, #f44236 0%, #f4e936 100%);
  background-image: -o-linear-gradient(left, #f44236 0%, #f4e936 100%);
  background-image: linear-gradient(to right, #f44236 0%, #f4e936 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f44236', endColorstr='#f4e936', GradientType=1);
  background-repeat: repeat-x;
}

.gradient-purple {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#a567e2),
    to(#e267d1)
  );
  background-image: -webkit-linear-gradient(left, #a567e2 0%, #e267d1 100%);
  background-image: -o-linear-gradient(left, #a567e2 0%, #e267d1 100%);
  background-image: linear-gradient(to right, #a567e2 0%, #e267d1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a567e2', endColorstr='#e267d1', GradientType=1);
  background-repeat: repeat-x;
}

/* Heading underline */
.h-underline {
  position: relative;
  padding-bottom: 1rem;
}

.h-underline:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
}

.h-underline-50:after {
  width: 50px;
}

.h-underline-100:after {
  width: 100px;
}

.h-underline-150:after {
  width: 150px;
}

.h-underline-primary:after {
  background-color: #3e70c9;
}

.h-underline-info:after {
  background-color: #20b9ae;
}

.h-underline-success:after {
  background-color: #43b968;
}

.h-underline-warning:after {
  background-color: #f59345;
}

.h-underline-danger:after {
  background-color: #f44236;
}

.h-underline-purple:after {
  background-color: #a567e2;
}

.h-underline-black:after {
  background-color: #333;
}

/* Overflow */
.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

/* Row */
.row.row-sm {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.row.row-sm [class*="col-"] {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.row.row-md {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.row.row-md [class*="col-"] {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.row.no-gutter {
  margin-left: 0;
  margin-right: 0;
}

.row.no-gutter > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

/* Shadow */
.shadow-primary {
  -webkit-box-shadow: 0 0 0 5px #3e70c9 !important;
  box-shadow: 0 0 0 5px #3e70c9 !important;
}

.shadow-info {
  -webkit-box-shadow: 0 0 0 5px #20b9ae !important;
  box-shadow: 0 0 0 5px #20b9ae !important;
}

.shadow-success {
  -webkit-box-shadow: 0 0 0 5px #43b968 !important;
  box-shadow: 0 0 0 5px #43b968 !important;
}

.shadow-warning {
  -webkit-box-shadow: 0 0 0 5px #f59345 !important;
  box-shadow: 0 0 0 5px #f59345 !important;
}

.shadow-danger {
  -webkit-box-shadow: 0 0 0 5px #f44236 !important;
  box-shadow: 0 0 0 5px #f44236 !important;
}

.shadow-purple {
  -webkit-box-shadow: 0 0 0 5px #a567e2 !important;
  box-shadow: 0 0 0 5px #a567e2 !important;
}

.shadow-black {
  -webkit-box-shadow: 0 0 0 5px #333 !important;
  box-shadow: 0 0 0 5px #333 !important;
}

.shadow-white {
  -webkit-box-shadow: 0 0 0 5px #fff !important;
  box-shadow: 0 0 0 5px #fff !important;
}

/* Text */
a.text-primary,
a.text-info,
a.text-success,
a.text-warning,
a.text-daner,
a.text-purple,
a.text-black,
a.text-white,
a.text-grey {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.text-primary {
  color: #3e70c9 !important;
}

.text-primary .underline {
  border-bottom: 1px solid #3e70c9 !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #82a2dc !important;
}

a.text-primary:focus .underline,
a.text-primary:hover .underline {
  border-color: #82a2dc !important;
}

.text-success {
  color: #43b968 !important;
}

.text-success .underline {
  border-bottom: 1px solid #43b968 !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #85d19d !important;
}

a.text-success:focus .underline,
a.text-success:hover .underline {
  border-color: #85d19d !important;
}

.text-info {
  color: #20b9ae !important;
}

.text-info .underline {
  border-bottom: 1px solid #20b9ae !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #5ad3e4 !important;
}

a.text-info:focus .underline,
a.text-info:hover .underline {
  border-color: #5ad3e4 !important;
}

.text-warning {
  color: #f59345 !important;
}

.text-warning .underline {
  border-bottom: 1px solid #f59345 !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #f8b986 !important;
}

a.text-warning:focus .underline,
a.text-warning:hover .underline {
  border-color: #f8b986 !important;
}

.text-danger {
  color: #f44236 !important;
}

.text-danger .underline {
  border-bottom: 1px solid #f44236 !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #f8847d !important;
}

a.text-danger:focus .underline,
a.text-danger:hover .underline {
  border-color: #f8847d !important;
}

.text-purple {
  color: #a567e2 !important;
}

.text-purple .underline {
  border-bottom: 1px solid #a567e2 !important;
}

a.text-purple:focus,
a.text-purple:hover {
  color: #c49cec !important;
}

a.text-purple:focus .underline,
a.text-purple:hover .underline {
  border-color: #c49cec !important;
}

.text-black {
  color: #333 !important;
}

.text-black .underline {
  border-bottom: 1px solid #333 !important;
}

a.text-black:focus,
a.text-black:hover {
  color: #7b7b7b !important;
}

a.text-black:focus .underline,
a.text-black:hover .underline {
  border-color: #7b7b7b !important;
}

.text-white {
  color: #fff !important;
}

.text-white .underline {
  border-bottom: 1px solid #fff !important;
}

a.text-white:focus,
a.text-white:hover {
  color: rgba(255, 255, 255, 0.7) !important;
}

a.text-white:focus .underline,
a.text-white:hover .underline {
  border-color: rgba(255, 255, 255, 0.7) !important;
}

.text-grey {
  color: #999 !important;
}

.text-grey .underline {
  border-bottom: 1px solid #999 !important;
}

a.text-grey:focus,
a.text-grey:hover {
  color: #aaa !important;
}

a.text-grey:focus .underline,
a.text-grey:hover .underline {
  border-color: #aaa !important;
}

.text-facebook {
  color: #3b5998 !important;
}

.text-twitter {
  color: #00aced !important;
}

.text-linkedin {
  color: #007bb6 !important;
}

.text-dribbble {
  color: #ea4c89 !important;
}

.text-googleplus {
  color: #dd4b39 !important;
}

.text-instagram {
  color: #517fa4 !important;
}

.text-pinterest {
  color: #cb2027 !important;
}

.text-dropbox {
  color: #007ee5 !important;
}

.text-flickr {
  color: #ff0084 !important;
}

.text-tumblr {
  color: #32506d !important;
}

.text-skype {
  color: #00aff0 !important;
}

.text-youtube {
  color: #bb0000 !important;
}

/* Minimum width */
.w-min-xs {
  min-width: 80px;
}

.w-min-sm {
  min-width: 100px;
}

.w-min-md {
  min-width: 120px;
}

.w-min-lg {
  min-width: 140px;
}

/* Other */
.font-90 {
  font-size: 90%;
}

.img-cover {
  background: #333;
  /* background: #dcdddf url(../img/loginBG.svg); */
  /* -webkit-background-size: cover; */
  /* background-size: cover; */
  /* background-repeat: no-repeat; */
}

.img-fixed {
  background-attachment: fixed;
}

.text-underline {
  text-decoration: underline !important;
}

/* Border */
.b-a-0 {
  border: none !important;
}

.b-t-0 {
  border-top: none !important;
}

.b-l-0 {
  border-left: none !important;
}

.b-b-0 {
  border-bottom: none !important;
}

.b-r-0 {
  border-right: none !important;
}

.b-a {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.b-t {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.b-l {
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}

.b-b {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.b-r {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}

.b-a-dashed {
  border-style: dashed !important;
}

.b-t-dashed {
  border-top-style: dashed !important;
}

.b-l-dashed {
  border-left-style: dashed !important;
}

.b-b-dashed {
  border-bottom-style: dashed !important;
}

.b-r-dashed {
  border-right-style: dashed !important;
}

.b-a-dotted {
  border-style: dotted !important;
}

.b-t-dotted {
  border-top-style: dotted !important;
}

.b-l-dotted {
  border-left-style: dotted !important;
}

.b-b-dotted {
  border-bottom-style: dotted !important;
}

.b-r-dotted {
  border-right-style: dotted !important;
}

.b-a-width-2 {
  border-width: 2px !important;
}

.b-a-width-3 {
  border-width: 3px !important;
}

.b-a-width-4 {
  border-width: 4px !important;
}

.b-a-width-5 {
  border-width: 5px !important;
}

.b-t-width-2 {
  border-top-width: 2px !important;
}

.b-t-width-3 {
  border-top-width: 3px !important;
}

.b-t-width-4 {
  border-top-width: 4px !important;
}

.b-t-width-5 {
  border-top-width: 5px !important;
}

.b-l-width-2 {
  border-left-width: 2px !important;
}

.b-l-width-3 {
  border-left-width: 3px !important;
}

.b-l-width-4 {
  border-left-width: 4px !important;
}

.b-l-width-5 {
  border-left-width: 5px !important;
}

.b-b-width-2 {
  border-bottom-width: 2px !important;
}

.b-b-width-3 {
  border-bottom-width: 3px !important;
}

.b-b-width-4 {
  border-bottom-width: 4px !important;
}

.b-b-width-5 {
  border-bottom-width: 5px !important;
}

.b-r-width-2 {
  border-right-width: 2px !important;
}

.b-r-width-3 {
  border-right-width: 3px !important;
}

.b-r-width-4 {
  border-right-width: 4px !important;
}

.b-r-width-5 {
  border-right-width: 5px !important;
}

.b-a-radius-0 {
  border-radius: 0 !important;
}

.b-a-radius-0-125 {
  border-radius: 0.125rem !important;
}

.b-a-radius-0-25 {
  border-radius: 0.25rem !important;
}

.b-a-radius-0-5 {
  border-radius: 0.5rem !important;
}

.b-a-radius-circle {
  border-radius: 50% !important;
}

.b-t-l-radius-0 {
  border-top-left-radius: 0 !important;
}

.b-t-l-radius-0-125 {
  border-top-left-radius: 0.125rem !important;
}

.b-t-l-radius-0-25 {
  border-top-left-radius: 0.25rem !important;
}

.b-t-l-radius-0-5 {
  border-top-left-radius: 0.5rem !important;
}

.b-t-l-radius-circle {
  border-top-left-radius: 50% !important;
}

.b-t-r-radius-0 {
  border-top-right-radius: 0 !important;
}

.b-t-r-radius-0-125 {
  border-top-right-radius: 0.125rem !important;
}

.b-t-r-radius-0-25 {
  border-top-right-radius: 0.25rem !important;
}

.b-t-r-radius-0-5 {
  border-top-right-radius: 0.5rem !important;
}

.b-t-r-radius-circle {
  border-top-right-radius: 50% !important;
}

.b-b-r-radius-0 {
  border-bottom-right-radius: 0 !important;
}

.b-b-r-radius-0-125 {
  border-bottom-right-radius: 0.125rem !important;
}

.b-b-r-radius-0-25 {
  border-bottom-right-radius: 0.25rem !important;
}

.b-b-r-radius-0-5 {
  border-bottom-right-radius: 0.5rem !important;
}

.b-b-r-radius-circle {
  border-bottom-right-radius: 50% !important;
}

.b-b-l-radius-0 {
  border-bottom-left-radius: 0 !important;
}

.b-b-l-radius-0-125 {
  border-bottom-left-radius: 0.125rem !important;
}

.b-b-l-radius-0-25 {
  border-bottom-left-radius: 0.25rem !important;
}

.b-b-l-radius-0-5 {
  border-bottom-left-radius: 0.5rem !important;
}

.b-b-l-radius-circle {
  border-bottom-left-radius: 50% !important;
}

.b-a-primary {
  border-color: #3e70c9 !important;
}

.b-t-primary {
  border-top-color: #3e70c9 !important;
}

.b-l-primary {
  border-left-color: #3e70c9 !important;
}

.b-b-primary {
  border-bottom-color: #3e70c9 !important;
}

.b-r-primary {
  border-right-color: #3e70c9 !important;
}

.b-a-secondary {
  border-color: #ccc !important;
}

.b-t-secondary {
  border-top-color: #ccc !important;
}

.b-l-secondary {
  border-left-color: #ccc !important;
}

.b-b-secondary {
  border-bottom-color: #ccc !important;
}

.b-r-secondary {
  border-right-color: #ccc !important;
}

.b-a-info {
  border-color: #20b9ae !important;
}

.b-t-info {
  border-top-color: #20b9ae !important;
}

.b-l-info {
  border-left-color: #20b9ae !important;
}

.b-b-info {
  border-bottom-color: #20b9ae !important;
}

.b-r-info {
  border-right-color: #20b9ae !important;
}

.b-a-success {
  border-color: #43b968 !important;
}

.b-t-success {
  border-top-color: #43b968 !important;
}

.b-l-success {
  border-left-color: #43b968 !important;
}

.b-b-success {
  border-bottom-color: #43b968 !important;
}

.b-r-success {
  border-right-color: #43b968 !important;
}

.b-a-warning {
  border-color: #f59345 !important;
}

.b-t-warning {
  border-top-color: #f59345 !important;
}

.b-l-warning {
  border-left-color: #f59345 !important;
}

.b-b-warning {
  border-bottom-color: #f59345 !important;
}

.b-r-warning {
  border-right-color: #f59345 !important;
}

.b-a-danger {
  border-color: #f44236 !important;
}

.b-t-danger {
  border-top-color: #f44236 !important;
}

.b-l-danger {
  border-left-color: #f44236 !important;
}

.b-b-danger {
  border-bottom-color: #f44236 !important;
}

.b-r-danger {
  border-right-color: #f44236 !important;
}

.b-a-purple {
  border-color: #a567e2 !important;
}

.b-t-purple {
  border-top-color: #a567e2 !important;
}

.b-l-purple {
  border-left-color: #a567e2 !important;
}

.b-b-purple {
  border-bottom-color: #a567e2 !important;
}

.b-r-purple {
  border-right-color: #a567e2 !important;
}

.b-a-black {
  border-color: #333 !important;
}

.b-t-black {
  border-top-color: #333 !important;
}

.b-l-black {
  border-left-color: #333 !important;
}

.b-b-black {
  border-bottom-color: #333 !important;
}

.b-r-black {
  border-right-color: #333 !important;
}

.b-a-white {
  border-color: #fff !important;
}

.b-t-white {
  border-top-color: #fff !important;
}

.b-l-white {
  border-left-color: #fff !important;
}

.b-b-white {
  border-bottom-color: #fff !important;
}

.b-r-white {
  border-right-color: #fff !important;
}

/* Margin */
.m-0-25 {
  margin: 0.25rem 0.25rem !important;
}

.mt-0-25 {
  margin-top: 0.25rem !important;
}

.mr-0-25 {
  margin-right: 0.25rem !important;
}

.mb-0-25 {
  margin-bottom: 0.25rem !important;
}

.ml-0-25 {
  margin-left: 0.25rem !important;
}

.mx-0-25 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.my-0-25 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.m-0-5 {
  margin: 0.5rem 0.5rem !important;
}

.mt-0-5 {
  margin-top: 0.5rem !important;
}

.mr-0-5 {
  margin-right: 0.5rem !important;
}

.mb-0-5 {
  margin-bottom: 0.5rem !important;
}

.ml-0-5 {
  margin-left: 0.5rem !important;
}

.mx-0-5 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.my-0-5 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.m-0-75 {
  margin: 0.75rem 0.75rem !important;
}

.mt-0-75 {
  margin-top: 0.75rem !important;
}

.mr-0-75 {
  margin-right: 0.75rem !important;
}

.mb-0-75 {
  margin-bottom: 0.75rem !important;
}

.ml-0-75 {
  margin-left: 0.75rem !important;
}

.mx-0-75 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.my-0-75 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

@media (min-width: 544px) {
  .m-sm-0 {
    margin: 0 0 !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0 {
    margin-left: 0 !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-sm-1 {
    margin: 1rem 1rem !important;
  }

  .mt-sm-1 {
    margin-top: 1rem !important;
  }

  .mr-sm-1 {
    margin-right: 1rem !important;
  }

  .mb-sm-1 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-1 {
    margin-left: 1rem !important;
  }

  .mx-sm-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .my-sm-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .m-sm-2 {
    margin: 1.5rem 1.5rem !important;
  }

  .mt-sm-2 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-2 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-2 {
    margin-left: 1.5rem !important;
  }

  .mx-sm-2 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .my-sm-2 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .m-sm-3 {
    margin: 3rem 3rem !important;
  }

  .mt-sm-3 {
    margin-top: 3rem !important;
  }

  .mr-sm-3 {
    margin-right: 3rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-3 {
    margin-left: 3rem !important;
  }

  .mx-sm-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .my-sm-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 0 !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0 {
    margin-left: 0 !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-md-1 {
    margin: 1rem 1rem !important;
  }

  .mt-md-1 {
    margin-top: 1rem !important;
  }

  .mr-md-1 {
    margin-right: 1rem !important;
  }

  .mb-md-1 {
    margin-bottom: 1rem !important;
  }

  .ml-md-1 {
    margin-left: 1rem !important;
  }

  .mx-md-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .my-md-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .m-md-2 {
    margin: 1.5rem 1.5rem !important;
  }

  .mt-md-2 {
    margin-top: 1.5rem !important;
  }

  .mr-md-2 {
    margin-right: 1.5rem !important;
  }

  .mb-md-2 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-2 {
    margin-left: 1.5rem !important;
  }

  .mx-md-2 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .my-md-2 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .m-md-3 {
    margin: 3rem 3rem !important;
  }

  .mt-md-3 {
    margin-top: 3rem !important;
  }

  .mr-md-3 {
    margin-right: 3rem !important;
  }

  .mb-md-3 {
    margin-bottom: 3rem !important;
  }

  .ml-md-3 {
    margin-left: 3rem !important;
  }

  .mx-md-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .my-md-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 0 !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0 {
    margin-left: 0 !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-lg-1 {
    margin: 1rem 1rem !important;
  }

  .mt-lg-1 {
    margin-top: 1rem !important;
  }

  .mr-lg-1 {
    margin-right: 1rem !important;
  }

  .mb-lg-1 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-1 {
    margin-left: 1rem !important;
  }

  .mx-lg-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .my-lg-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .m-lg-2 {
    margin: 1.5rem 1.5rem !important;
  }

  .mt-lg-2 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-2 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-2 {
    margin-left: 1.5rem !important;
  }

  .mx-lg-2 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .my-lg-2 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .m-lg-3 {
    margin: 3rem 3rem !important;
  }

  .mt-lg-3 {
    margin-top: 3rem !important;
  }

  .mr-lg-3 {
    margin-right: 3rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-3 {
    margin-left: 3rem !important;
  }

  .mx-lg-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .my-lg-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 0 !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0 {
    margin-left: 0 !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-xl-1 {
    margin: 1rem 1rem !important;
  }

  .mt-xl-1 {
    margin-top: 1rem !important;
  }

  .mr-xl-1 {
    margin-right: 1rem !important;
  }

  .mb-xl-1 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-1 {
    margin-left: 1rem !important;
  }

  .mx-xl-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .my-xl-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .m-xl-2 {
    margin: 1.5rem 1.5rem !important;
  }

  .mt-xl-2 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-2 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-2 {
    margin-left: 1.5rem !important;
  }

  .mx-xl-2 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .my-xl-2 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .m-xl-3 {
    margin: 3rem 3rem !important;
  }

  .mt-xl-3 {
    margin-top: 3rem !important;
  }

  .mr-xl-3 {
    margin-right: 3rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-3 {
    margin-left: 3rem !important;
  }

  .mx-xl-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .my-xl-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
}

/* Padding */
.pa-0-25 {
  padding: 0.25rem 0.25rem !important;
}

.pt-0-25 {
  padding-top: 0.25rem !important;
}

.pr-0-25 {
  padding-right: 0.25rem !important;
}

.pb-0-25 {
  padding-bottom: 0.25rem !important;
}

.pl-0-25 {
  padding-left: 0.25rem !important;
}

.px-0-25 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.py-0-25 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.pa-0-5 {
  padding: 0.5rem 0.5rem !important;
}

.pt-0-5 {
  padding-top: 0.5rem !important;
}

.pr-0-5 {
  padding-right: 0.5rem !important;
}

.pb-0-5 {
  padding-bottom: 0.5rem !important;
}

.pl-0-5 {
  padding-left: 0.5rem !important;
}

.px-0-5 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.py-0-5 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.pa-0-75 {
  padding: 0.75rem 0.75rem !important;
}

.pt-0-75 {
  padding-top: 0.75rem !important;
}

.pr-0-75 {
  padding-right: 0.75rem !important;
}

.pb-0-75 {
  padding-bottom: 0.75rem !important;
}

.pl-0-75 {
  padding-left: 0.75rem !important;
}

.px-0-75 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.py-0-75 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

/* =================================================================
  Components
================================================================= */

/* Animate.css */
@media (max-width: 767px) {
  .animated {
    /* CSS transitions */
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    /* CSS transforms */
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    /* CSS animations */
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}

/* Jscrollpane */
.jspPane {
  width: 100% !important;
}

.jspVerticalBar {
  right: 4px;
  width: 4px;
  height: 100%;
  background: transparent;
}

.jspTrack {
  background: transparent;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.jspContainer:hover .jspTrack {
  opacity: 1;
}

.jspDrag {
  border-radius: 0.25rem;
}

.custom-scroll-light .jspDrag {
  background: rgba(255, 255, 255, 0.1);
}

.custom-scroll-dark .jspDrag {
  background: rgba(0, 0, 0, 0.1);
}

/* jVectorMap */
.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
  border-radius: 0;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px;
}

.jvectormap-zoomout {
  top: 35px;
}

/* SweetAlert2 */
.swal2-modal {
  border-radius: 0;
}

/* Toastr */
#toast-container > div {
  border-radius: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#toast-container > :hover {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.toast-success {
  background-color: #43b968;
}

.toast-error {
  background-color: #f44236;
}

.toast-info {
  background-color: #20b9ae;
}

.toast-warning {
  background-color: #f59345;
}

/* Waves */
.waves-effect.waves-light .waves-ripple {
  background: rgba(255, 255, 255, 0.1);
  background: -webkit-radial-gradient(
    rgba(255, 255, 255, 0.1) 0,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.1) 70%,
    rgba(255, 255, 255, 0) 90%
  );
  background: -o-radial-gradient(
    rgba(255, 255, 255, 0.1) 0,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.1) 70%,
    rgba(255, 255, 255, 0) 90%
  );
  background: -moz-radial-gradient(
    rgba(255, 255, 255, 0.1) 0,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.1) 70%,
    rgba(255, 255, 255, 0) 90%
  );
  background: radial-gradient(
    rgba(255, 255, 255, 0.1) 0,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.1) 70%,
    rgba(255, 255, 255, 0) 90%
  );
}

.waves-effect.waves-classic.waves-light .waves-ripple {
  background: rgba(255, 255, 255, 0.1);
}

/* =================================================================
  Animations
================================================================= */

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation: fadeInUp 1s 1;
  -moz-animation: fadeInUp 1s 1;
  -ms-animation: fadeInUp 1s 1;
  -o-animation: fadeInUp 1s 1;
  animation: fadeInUp 1s 1;
}

@-webkit-keyframes fadeInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInBottom {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInBottom {
  0% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInBottom {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInBottom {
  -webkit-animation: fadeInBottom 1s 1;
  -moz-animation: fadeInBottom 1s 1;
  -ms-animation: fadeInBottom 1s 1;
  -o-animation: fadeInBottom 1s 1;
  animation: fadeInBottom 1s 1;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeft {
  -webkit-animation: fadeInLeft 1s 1;
  -moz-animation: fadeInLeft 1s 1;
  -ms-animation: fadeInLeft 1s 1;
  -o-animation: fadeInLeft 1s 1;
  animation: fadeInLeft 1s 1;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight {
  -webkit-animation: fadeInRight 1s 1;
  -moz-animation: fadeInRight 1s 1;
  -ms-animation: fadeInRight 1s 1;
  -o-animation: fadeInRight 1s 1;
  animation: fadeInRight 1s 1;
}

@-webkit-keyframes dashoffset {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -20px;
  }
}

@-o-keyframes dashoffset {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -20px;
  }
}

@keyframes dashoffset {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -20px;
  }
}

@-webkit-keyframes bouncing-stroke {
  0%,
  100% {
    stroke-width: 5px;
  }

  50% {
    stroke-width: 10px;
  }
}

@-o-keyframes bouncing-stroke {
  0%,
  100% {
    stroke-width: 5px;
  }

  50% {
    stroke-width: 10px;
  }
}

@keyframes bouncing-stroke {
  0%,
  100% {
    stroke-width: 5px;
  }

  50% {
    background-position: center center;
    stroke-width: 10px;
  }
}

@-webkit-keyframes exploding-stroke {
  0% {
    stroke-width: 2px;
    opacity: 1;
  }

  100% {
    stroke-width: 20px;
    opacity: 0;
  }
}

@-o-keyframes exploding-stroke {
  0% {
    stroke-width: 2px;
    opacity: 1;
  }

  100% {
    stroke-width: 20px;
    opacity: 0;
  }
}

@keyframes exploding-stroke {
  0% {
    stroke-width: 2px;
    opacity: 1;
  }

  100% {
    stroke-width: 20px;
    opacity: 0;
  }
}

@-webkit-keyframes writing {
  0% {
    left: 0;
  }

  100% {
    left: 6px;
  }
}

@-o-keyframes writing {
  0% {
    left: 0;
  }

  100% {
    left: 6px;
  }
}

@keyframes writing {
  0% {
    left: 0;
  }

  100% {
    left: 6px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes rotate {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes rotate {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* TABS */
.template-options .nav-tabs {
  width: 100%;
  background-color: #602d91;
  border: none;
}

.template-options .nav-tabs .nav-item {
  display: table-cell;
  float: none;
  margin-bottom: 0;
  width: 1%;
  text-align: center;
}

.template-options .nav-tabs .nav-item + .nav-item {
  margin-left: 0;
}

.template-options .nav-tabs .nav-link {
  padding: 1em 1em;
  color: #fff;
  border: none;
}

.template-options .nav-tabs .nav-link.active,
.template-options .nav-tabs .nav-link.active:focus,
.template-options .nav-tabs .nav-link.active:hover {
  color: #333;
}

.template-options .nav-tabs .nav-item.open .nav-link,
.template-options .nav-tabs .nav-item.open .nav-link:focus,
.template-options .nav-tabs .nav-item.open .nav-link:hover {
  border-color: #eee;
}

.padd-80 {
  padding: 0 80px;
}

.copy_text {
  font-size: 11px;
}

.join-button {
  margin: 0px auto;
  width: 50%;
  padding-bottom: 15px;
  text-align: center;
}

.display-flex {
  display: flex !important;
}

.img-cover {
  font: 14px "Titillium Web" !important;
  overflow: hidden;
}

span.rem_span.custom-control-description {
  padding: 0 5px;
}

span.nav-link {
  cursor: pointer;
  position: relative;
  color: #0275d8;
}

.bg-0275d8 {
  background-color: #0275d8 !important;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #0275d8 !important;
  border-color: #0275d8 !important;
}

#media-device-permission-error {
  display: none;
}

#unsupported_browser_message {
  display: none;
}

.border-top-gray {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

@media (max-width: 767px) {
}

@media screen and (min-width: 768px) {
}

@media (min-width: 481px) and (max-width: 767px) {
  .pad-20-30 {
    padding: 9px 0px !important;
    width: 55px;
    margin: 0 auto;
    font-size: 10px;
  }

  .site-sidebar-second {
    width: 100%;
    bottom: 0px;
    right: -688px;
  }

  .vcx_chat_container .frame {
    height: 61vh;
  }

  .vcx_chat_container .mytext {
    padding: 10px 0;
  }

  .site-header .navbar-left {
    background-color: #222123 !important;
  }

  /*.btn-device-apply,.btn-at-selection-apply,.btn-invite-apply{*/
  /*margin: 10px 0;*/
  /*}*/
  .site-header .navbar-nav > li > a i {
    color: #000;
  }

  .ml-1 {
    font-weight: 700;
    color: #000;
    font-size: 18px;
  }
}

#remote-name {
  color: #ffffff;
  z-index: 200000;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  bottom: 5px;
}

.col-sm-6 {
  padding: 0px !important;
}

#self-view {
  background: #000;
  /*border-radius: 5px;*/
}

#self-view video {
  /*border: 5px solid #948888;*/
  /*border-radius: 5px;*/
}

.self-name {
  /*color: #ffffff;*/
  /*text-align: center;*/
  /*font-size: 15px;*/
  /*font-weight: bold;*/
}

/* .toolbar-buttons {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  min-width: 350px;
  z-index: 9999999;
} */
#chat_window-popup {
  height: 100vh;
  z-index: 999999;
  width: 30%;
  max-width: 400px;
  position: fixed;
  top: 0;
  right: 0;
  display: none;
}

#chat_container {
  height: 100vh;
}

.mute-icon,
.video-mute-icon {
  background: #fff;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50px;
  display: inline-block;
  line-height: 50px;
  font-size: 25px;
}

#disconnect_call {
  border-radius: 50px;
  font-size: 20px;
}

.end-call-button {
  display: inline-block;
  margin: 0 25px 11px 25px;
}

.remote-name {
  color: white;
  font-size: x-large;
}

#call_div {
  /*background: black;*/
}

.duration {
  color: white;
  font-size: large;
}

#logo_div {
  z-index: 100000;
  position: fixed;
  top: 0px;
  left: 30%;
  transform: translate(50%, 50%);
  text-align: center;
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  cursor: pointer;
}

#overlay img {
  max-width: 5%;
  position: fixed;
  top: 45%;
  left: 50%;
}

.widget-info-twt {
  padding: 30px 20px;
  /*border-radius: 0;*/
  /*-webkit-border-radius: 0;*/
  text-align: center;
}

.widget-info-twt h5 {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 6px;
  margin-top: 0;
}

.widget-info-twt .subtitle,
.widget-info-twt .followers {
  color: #f2f2f2;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 30px;
  display: block;
}

.widget-info-twt .followers span {
  padding: 0 5px;
}

.widget-info-twt .avatar img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  display: inline-block;
  height: 90px;
  width: 90px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 30px;
}

.btn-follow {
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  width: 100%;
  /*border-radius: 0%;*/
  /*-webkit-border-radius: 0%;*/
}

.sweet-alert button {
  padding: 10px 15px;
}

.first-latter-icon {
  /* background: #bab7dd; */
  /* width: 125px; */
  /* height: 125px; */
  /* border-radius: 50%; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 60px;
  color: #051867;
  margin-top: 20px;
}

/************Chat panel ***************/

#frame {
  height: 100%;
  /*background: #E6EAEA;*/
}

@media screen and (max-width: 360px) {
  #frame {
    width: 100%;
    height: 100vh;
  }
}

#frame .content {
  height: 100%;
  overflow: hidden;
  position: relative;
}

#frame .content .contact-profile {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: #f5f5f5;
}

#frame .content .contact-profile img {
  width: 40px;
  border-radius: 50%;
  float: left;
  margin: 9px 12px 0 9px;
}

#frame .content .contact-profile p {
  float: left;
}

#frame .content .contact-profile .social-media {
  float: right;
}

#frame .content .contact-profile .social-media i {
  margin-left: 14px;
  cursor: pointer;
}

#frame .content .contact-profile .social-media i:nth-last-child(1) {
  margin-right: 20px;
}

#frame .content .contact-profile .social-media i:hover {
  color: #435f7a;
}

/*#frame .content .messages {*/
/*height: auto;*/
/*min-height: calc(100% - 93px);*/
/*max-height: calc(100% - 93px);*/
/*overflow-y: scroll;*/
/*overflow-x: hidden;*/
/*}*/
@media screen and (max-width: 735px) {
  #frame .content .messages {
    max-height: calc(100% - 105px);
  }
}

#frame .content .messages::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

#frame .content .messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}

#frame .content .message-input {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 99;
}

#frame .content .message-input .wrap {
  position: relative;
}

#frame .content .message-input .wrap input {
  font-family: "proxima-nova", "Source Sans Pro", sans-serif;
  float: left;
  border: none;
  width: calc(100% - 90px);
  padding: 11px 32px 10px 8px;
  font-size: 0.8em;
  color: #32465a;
}

@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap input {
    padding: 15px 32px 16px 8px;
  }
}

#frame .content .message-input .wrap input:focus {
  outline: none;
}

#frame .content .message-input .wrap .attachment {
  position: absolute;
  right: 60px;
  z-index: 4;
  margin-top: 10px;
  font-size: 1.1em;
  color: #435f7a;
  opacity: 0.5;
  cursor: pointer;
}

@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap .attachment {
    margin-top: 17px;
    right: 65px;
  }
}

#frame .content .message-input .wrap .attachment:hover {
  opacity: 1;
}

#frame .content .message-input .wrap button {
  float: right;
  border: none;
  width: 50px;
  padding: 12px 0;
  cursor: pointer;
  background: #32465a;
  color: #f5f5f5;
}

@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap button {
    padding: 16px 0;
  }
}

#frame .content .message-input .wrap button:hover {
  background: #435f7a;
}

#frame .content .message-input .wrap button:focus {
  outline: none;
}

.vcx_player {
  height: 100%;
  overflow: hidden;
}

video {
  object-fit: cover;
}

.SC_inner {
  width: 100%;
}

div#rec_notification {
  z-index: 100000;
  position: fixed;
  top: 0px;
  right: 30%;
  transform: translate(50%, 50%);
  text-align: center;
  color: white;
}

div#enable_stats {
  text-align: center;
  color: black;
}

#rec_notification {
  display: none;
}

.blink-image {
  -moz-animation: blink normal 2s infinite ease-in-out;
  /* Firefox */
  -webkit-animation: blink normal 2s infinite ease-in-out;
  /* Webkit */
  -ms-animation: blink normal 2s infinite ease-in-out;
  /* IE */
  animation: blink normal 2s infinite ease-in-out;
  /* Opera and prob css3 final iteration */
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* IE */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.logout_div {
  float: right;
  margin: 10px;
  color: red;
  cursor: pointer;
}

.chat-message {
  list-style: none;
  margin-right: 20px;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 0;
}

.chat-message li {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px dotted #b3a9a9;
}

.chat-message li.left .chat-body {
  margin-left: 60px;
}

.chat-message li.right .chat-body {
  margin-right: 60px;
}

.chat-message li .chat-body p {
  margin: 0;
  /*color: #777777;*/
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.red {
  height: 50px;
  width: 50px;
  text-align: center;
  vertical-align: center;
  line-height: 50px;
  font-size: large;
  color: white;
  background: red;
}

.sky_blue {
  height: 50px;
  width: 50px;
  text-align: center;
  vertical-align: center;
  line-height: 50px;
  font-size: large;
  color: white;
  background: #55c1e7;
}

.img-circle {
  border-radius: 50%;
}

.call-top-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px 2rem 0 10px;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 22222222;
  color: #fff;
}

.local_class_peep {
  width: 150px;
  height: 170px;
  position: fixed;
  top: 60px;
  left: 10px;
  z-index: 100000;
}

.local_class_grid {
  height: 100vh;
  width: 50%;
  z-index: 100000;
}

.remote_class_peep {
  height: 100vh;
  width: 100%;
}

.remote_class_grid {
  height: 100vh;
  width: 50%;
}

canvas {
  cursor: crosshair;
  border: 1px solid #000000;
}

#chat-tag {
  position: absolute;
  top: 5px !important;
  margin-left: -3px !important;
  font-size: 80% !important;
  display: inline-block;
  padding: 4px 2px;
  font-size: 11px !important;
  font-weight: 600 !important;
  line-height: 1;
  color: #fff;
  border-radius: 50% !important;
}

.toolbar-buttons {
  position: absolute;
  bottom: 2%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  min-width: 350px;
  z-index: 9999999;
}

.toolbar-buttons .tools {
  display: flex;
  flex-direction: row;
}

.toolbar-buttons .tools > div {
  margin: 4px;
}

.remote-name {
  color: white;
  font-size: x-large;
  color: white;
  position: absolute;
  z-index: 100;
  bottom: 6px;
  font-size: 16px;
  left: 3%;
  width: 100%;
}

#stream11 {
  object-fit: contain;
}

@media (min-width: 320px) and (max-width: 480px) {
  #chat_window-popup {
    height: 88vh;
    width: 60%;
  }

  .padd-80 {
    padding: 0 20px;
  }

  .pad-20-30 {
    padding: 9px 0px !important;
    width: 45px;
    margin: 0 auto;
    font-size: 10px;
  }

  .site-sidebar-second {
    width: 100%;
    bottom: 0px;
    right: -688px;
  }

  .vcx_chat_container .frame {
    height: 61vh;
  }

  .vcx_chat_container .mytext {
    padding: 10px 0;
  }

  .site-header .navbar-left {
    background-color: #222123 !important;
  }

  /*.btn-device-apply,.btn-at-selection-apply,.btn-invite-apply{*/
  /*margin: 10px 0;*/
  /*}*/
  .site-header .navbar-nav > li > a i {
    color: #000;
  }

  .ml-1 {
    font-weight: 700;
    color: #000;
    font-size: 18px;
  }

  .toolbar-buttons {
    position: absolute;
    bottom: 5%;
    left: 6%;
    transform: translate(0, 0);
    min-width: 50px;
  }

  .local_class_grid {
    height: 50vh;
    width: auto;
  }

  .remote_class_grid {
    height: 50vh;
    width: auto;
  }

  .toolbar-buttons .tools {
    flex-direction: column;
  }

  .toolbar-buttons .tools > div {
    margin: 2px 0;
  }

  .remote-name,
  .self-name {
    color: white;
    position: absolute;
    z-index: 100;
    bottom: 6px;
    font-size: 16px;
    left: 6%;
    width: 100%;
  }
}